import React, {useState, useEffect} from "react";
import { withLocalize } from 'react-localize-redux';
import { useParams, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import { doctorLoginFromStorage } from "../../../redux/slices/doctors/doctorSlices";
import AdminDoctorMacForm, {IDoctorMacFormState, INITIAL_DOCTOR_MAC_FORM_STATE} from "./AdminDoctorMacForm";
import AdminDoctorForm, {IDoctorFormState, INITIAL_DOCTOR_FORM_STATE} from "./AdminDoctorForm";
import { updateUserAction } from "../../../redux/slices/doctors/usersSlices";
import {RootState, AppDispatch} from '../../../redux/store/store';
import { getOneUserAction } from "../../../redux/slices/doctors/usersSlices";
import { getMacsAction } from "../../../redux/slices/doctors/macsSlices";

interface IState {
}

interface IProps {
  translate: any;
}

type MacObject = {
  _id: string;
  mac: string;
};

function createIdMacPairs(array: MacObject[]): { [key: string]: string } {
  const result: { [key: string]: string } = {};
  array.forEach(item => {
    result[item._id] = item.mac;
  });
  return result;
}

const INITIAL_FORM_STATE: IDoctorMacFormState = {
  ...INITIAL_DOCTOR_MAC_FORM_STATE
};

const INITIAL_DOCTOR_STATE: IDoctorFormState = {
  ...INITIAL_DOCTOR_FORM_STATE
}

const AdminDoctorMacNew: React.FC<IProps> = ({translate}) => {
  const [doctor, setDoctor] = useState({...INITIAL_DOCTOR_STATE});
  const [idMacObj, setIdMacObj] = useState<{ [key: string]: string }>({});
  const {id} = useParams();

  const {access_token} =JSON.parse(JSON.stringify(doctorLoginFromStorage));

  const dispatch: AppDispatch = useDispatch();

  const users: any = useSelector((state: RootState) => state?.users);

  const macs: any = useSelector((state: RootState) => state?.macs);

  const [showing, setShowing] = useState(false);
  const navigate = useNavigate();

  const handleSubmit= (values: any) => {
      //dispath the action
    console.log("MAC VALUES", values);
    const devices: string[] = [...doctor.devices, values.mac];
    console.log("DEVICES", devices);
    const doctorWithDevices= {...doctor, devices};
    console.log("DOCTOR DEVICES", doctorWithDevices);
    const tokenUser = {
      doctor: doctorWithDevices,
      access_token
    };
    setShowing(true);
    dispatch(updateUserAction(tokenUser) as any);
  };

  // device or mac
  useEffect(() => {
    const token = {
      access_token,
    };

    dispatch(getMacsAction(token));
    
  }, [access_token, dispatch]); // ?? []

  useEffect(() => {

    if (macs.loading === false && macs.calledList.devices) {
      const idMacPairs = createIdMacPairs(macs.calledList.devices);
      setIdMacObj(idMacPairs);
    }
  }, [macs],)

  // user or doctor
  useEffect(() => {
    const token = {
      access_token,
      user_id: id
    };

    dispatch(getOneUserAction(token));
    
  }, [access_token, dispatch, id]); // ?? []

  useEffect(() => {

    if (users.loading === false && users.updated && showing) {
      navigate(`/admin/doctors/${id}/macs`); // TODO
    }
  }, [users, id, navigate, showing]);

  useEffect(() => {

    if (users.loading === false && users.called) {
      const { doctor } = users.called;
      const { password, ...other} = doctor;
      setDoctor(other);
    }
  }, [users],)

  if (macs.loading === true || users.loading === true || !doctor) {
    return <div>Loading...</div>
  };

  const TODO= {
    backgroundColor: '#ffffff', //'yellow',
    maxWidth: "340px",
    margin: 'auto',
  };

  return (
    <div style = {TODO}>
      <div style={{color: "red"}}>{users.appErr}</div>
      <div style={{color: "red"}}>{users.serverErr}</div>
      <div style={{color: "red"}}>{macs.appErr}</div>
      <div style={{color: "red"}}>{macs.serverErr}</div>
      <AdminDoctorMacForm
        initialState = {{...INITIAL_FORM_STATE}}
        translate= {translate}
        handleSubmit = {handleSubmit}
        idMacPairs = {idMacObj}
      />
    </div>
  );
};

export default withLocalize(AdminDoctorMacNew);
