import React, {useState, useEffect} from "react";
import { withLocalize } from 'react-localize-redux';
import { useParams, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import { doctorLoginFromStorage } from "../../../redux/slices/doctors/doctorSlices";
import config from "config";
import DoctorPatientForm, {IPatientFormState, INITIAL_PATIENT_FORM_STATE} from "./DoctorPatientForm";
import { updatePatientAction } from "../../../redux/slices/doctors/patientsSlices";
import {RootState, AppDispatch} from '../../../redux/store/store';
import { getOnePatientAction } from "../../../redux/slices/doctors/patientsSlices";

interface IState {
}

interface IProps {
  translate: any;
}

const correctDate = (date: any) => {
  return `${date}`.slice(0,10);
}

const defaultStateCountry: any =  {
  profile: { address: {
    ...INITIAL_PATIENT_FORM_STATE.profile.address,
    state: config.mexicoStates.indexOf("Nuevo León"),
    country: config.country.indexOf("Mexico")
  }}};
const INITIAL_FORM_STATE: IPatientFormState = {
  ...INITIAL_PATIENT_FORM_STATE, ...defaultStateCountry
};

const DoctorPatientEdit: React.FC<IProps> = ({translate}) => {
  const [patient, setPatient] = useState({...INITIAL_FORM_STATE})

  const {id} = useParams();

  const {access_token} =JSON.parse(JSON.stringify(doctorLoginFromStorage));

  const dispatch: AppDispatch = useDispatch();


  const store: any = useSelector((state: RootState) => state?.patients);
  const {called, updated, loading, serverErr, appErr} = store;
  const [showing, setShowing] = useState(false);
  const navigate = useNavigate();

  const handleSubmit= (values: any, onSubmitProps: any) => {
      //dispath the action
    const { doctor, __v, whats_app, ...otherValues} = values;
    //console.log("UPDATE ", otherValues);
    const tokenPatient = {
      patient: {...values},
      access_token
    };
    setShowing(true);
    dispatch(updatePatientAction(tokenPatient) as any);
  };

  useEffect(() => {
    const token = {
      access_token,
      patient_id: id
    };

    dispatch(getOnePatientAction(token));
    
  }, [access_token, dispatch, id]); // ?? []

  useEffect(() => {

    if (loading === false && updated && showing) {
      navigate(`/doctor/patients/${id}`);
    }
  }, [loading, updated, id, navigate, showing])

  useEffect(() => {

    if (loading === false && called) {

      const { patient } = called;

      const profile =  {
        ...patient.profile,
        dob: correctDate(patient.profile.dob)
      }
      const history = {
        ...patient.history,
        last_pap_smear: correctDate(patient.history.last_pap_smear),
        last_menstruation: correctDate(patient.history.last_menstruation),
      }

      const copyPatient = {...patient, profile, history};

      setPatient(copyPatient);
    }
  }, [loading, called],)

  if (loading === true || !patient) {
    return <div>Loading...</div>
  };

  const TODO= {
    backgroundColor: '#ffffff', //'yellow',
    maxWidth: "340px",
    margin: 'auto',
  };

  return (
    <div style = {TODO}>
      <div style={{color: "red"}}>{appErr}</div>
      <div style={{color: "red"}}>{serverErr}</div>
      <DoctorPatientForm
        initialState = {{...INITIAL_PATIENT_FORM_STATE, ...patient}}
        translate= {translate}
        handleSubmit = {handleSubmit}
      />
    </div>
  );
};

export default withLocalize(DoctorPatientEdit);
