import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { baseUrl } from "../../../utils/baseURL";

const USERS_INFO = "usersInfo";

export interface UsersState {
  usersInfo: any
  loading: boolean;
  appErr: any;
  serverErr: any;
  called: string;
  created: string;
  updated: string;
  registered: string;
}

// update settings action
export const getUsersAction: any = createAsyncThunk(
  "users/getAll/users",
  async (token, {rejectWithValue, getState, dispatch}) => {

    const {access_token} = token as any;
    const config = {
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Basic ${access_token}`
      },
    };

    try {
      const { data } = await axios.get(
        `${baseUrl}/api/v1/admin/doctors`,
        config
      );
      return data;
    } catch (error: any) {
      if (!error?.response) {
        throw error;
      }
      return rejectWithValue(error?.response?.data);
    }
  }
);

// update settings action
export const getOneUserAction: any = createAsyncThunk(
  "users/getOne/user",
  async (tokenUserId, {rejectWithValue, getState, dispatch}) => {

    const {access_token, user_id} = tokenUserId as any;
    const config = {
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Basic ${access_token}`
      },
    };

    try {
      const { data } = await axios.get(
        `${baseUrl}/api/v1/admin/doctors/${user_id}/detail`,
        config
      );
      return data;
    } catch (error: any) {
      if (!error?.response) {
        throw error;
      }
      return rejectWithValue(error?.response?.data);
    }
  }
);

//TODO
// create user action
export const createUserAction: any = createAsyncThunk(
  "users/create/user",
  async (tokenUser, {rejectWithValue, getState, dispatch}) => {

    const {doctor, access_token} = tokenUser as any;
    console.log("USER INFO", doctor);
    const body = {
      doctor
    };
    const config = {
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Basic ${access_token}`
      },
    };

    try {
      const { data } = await axios.post(
        `${baseUrl}/api/v1/doctors/newdoctor`,
        body,
        config
      );
      return data;
    } catch (error: any) {
      if (!error?.response) {
        throw error;
      }
      return rejectWithValue(error?.response?.data);
    }
  }
);
//TODO
// update settings action
export const updateUserAction: any = createAsyncThunk(
  "users/update/user",
  async (tokenUser, {rejectWithValue, getState, dispatch}) => {

    const {doctor, access_token} = tokenUser as any;
    const body = {
      doctor
    };
    const config = {
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Basic ${access_token}`
      },
    };

    try {
      const { data } = await axios.put(
        `${baseUrl}/api/v1/admin/doctors`,
        body,
        config
      );
      return data;
    } catch (error: any) {
      if (!error?.response) {
        throw error;
      }
      return rejectWithValue(error?.response?.data);
    }
  }
);

// get settings from local storage and place into store
const settingsFromStorage: string = localStorage.getItem(USERS_INFO)
  ? JSON.parse(localStorage.getItem(USERS_INFO) || "")
  : null;

const initialState: UsersState = {
  usersInfo: settingsFromStorage,
  loading: false,
  appErr: undefined,
  serverErr: undefined,
  called: "",
  updated: "",
  created: "",
  registered: ""
};

const userSlices = createSlice({
  name: "users",
  initialState,
  extraReducers: (builder:any) => {
    // appErrUndefined
    // get All users
    builder.addCase(getUsersAction.pending, (state: UsersState, action: any) => {
      state.loading = true;
      state.appErr = undefined;
      state.serverErr = undefined;
    });
    builder.addCase(getUsersAction.fulfilled, (state:UsersState, action: any) => {
      state.loading = false;
      state.registered = action?.payload;
      state.appErr = undefined;
      state.serverErr = undefined;
    });
    builder.addCase(getUsersAction.rejected, (state: UsersState, action: any) => {
      state.loading = false;
      //state.appErr = action?.payload?.message;
      state.appErr = action?.payload?.JsonWebTokenError;
      state.serverErr = action?.error?.message;
    });

    // get One user
    builder.addCase(getOneUserAction.pending, (state: UsersState, action: any) => {
      state.loading = true;
      state.appErr = undefined;
      state.serverErr = undefined;
    });
    builder.addCase(getOneUserAction.fulfilled, (state:UsersState, action: any) => {
      state.loading = false;
      state.called = action?.payload;
      state.appErr = undefined;
      state.serverErr = undefined;
    });
    builder.addCase(getOneUserAction.rejected, (state: UsersState, action: any) => {
      state.loading = false;
      //state.appErr = action?.payload?.message;
      state.appErr = action?.payload?.JsonWebTokenError;
      state.serverErr = action?.error?.message;
    });

    // create user
    builder.addCase(createUserAction.pending, (state: UsersState, action: any) => {
      state.loading = true;
      state.appErr = undefined;
      state.serverErr = undefined;
    });
    builder.addCase(createUserAction.fulfilled, (state:UsersState, action: any) => {
      state.loading = false;
      state.created = action?.payload;
      state.appErr = undefined;
      state.serverErr = undefined;
    });
    builder.addCase(createUserAction.rejected, (state: UsersState, action: any) => {
      state.loading = false;
      //state.appErr = action?.payload?.message;
      state.appErr = action?.payload?.JsonWebTokenError;
      state.serverErr = action?.error?.message;
    });

    // update user
    builder.addCase(updateUserAction.pending, (state: UsersState, action: any) => {
      state.loading = true;
      state.appErr = undefined;
      state.serverErr = undefined;
    });
    builder.addCase(updateUserAction.fulfilled, (state:UsersState, action: any) => {
      state.loading = false;
      state.updated = action?.payload;
      state.appErr = undefined;
      state.serverErr = undefined;
    });
    builder.addCase(updateUserAction.rejected, (state: UsersState, action: any) => {
      state.loading = false;
      //state.appErr = action?.payload?.message;
      state.appErr = action?.payload?.JsonWebTokenError;
      state.serverErr = action?.error?.message;
    });
  },
} as any);

export default userSlices.reducer;
