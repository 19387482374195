//import  language from 'languagesp';
import React from 'react';
import { Box, Typography } from '@mui/material'
import Textfield from '../../FormsUI/Textfield';
import Button from '../../FormsUI/Button';
import { withLocalize } from 'react-localize-redux';
import { Link } from 'react-router-dom'
import { Formik, Form, FormikProps } from "formik";
import { Navigate, useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import { appErrUndefinedAction, changePasswordAction } from "../../../redux/slices/doctors/doctorSlices";
import {RootState, AppDispatch} from '../../../redux/store/store';

//import './Login.scss'

interface IProps {
  translate: any;
  languages: any;
}

interface IState {
}
interface IValues {
  password: string;
  newpassword: string;
}

const INITIAL_FORM_STATE = {
  password: "",
  newpassword: "",
};

//Form schema
const FORM_VALIDATION = Yup.object().shape({
   password: Yup.string().required("New password is required"),
   newpassword: Yup.string().required("New password confirmation is required"),
});

const ChangePassword: React.FC<IProps>= ({translate, languages}) => {
  const [searchParams] = useSearchParams();
  //const access_token = searchParams.get("access_token");
  let language;
  for (let i = 0; i< languages.length; i++) {
    if (languages[i].active) {
      language = languages[i].code;
    }
  }

  const dispatch: AppDispatch = useDispatch();

  const setAppErrorUndefined = () => {
    dispatch(appErrUndefinedAction());
  }

  //formik
  const handleSubmit =  (values: any) => {
    if (values.password === values.newpassword) {
      //dispath the action
      const tokenPass = {
        password: values.password,
        access_token: searchParams.get("access_token")
      };
      dispatch(changePasswordAction(tokenPass) as any);
    }
    //console.log(values);
  };

  // redirect
  const store: any = useSelector((state: RootState) => state?.doctors);
  const {registered, loading, serverErr, appErr} = store;
  if (registered.doctor) {
    return <Navigate replace={true} to="/login" />
  }

  const TODO= {
    backgroundColor: '#ffff77', //'yellow',
    maxWidth: "320px",
    margin: 'auto',
  };

  return (
    <>
      <Box
        sx={TODO}
      >
        <Box //className='login-container'
          sx={{
            //height: 'calc(100vh - 48px)',
          }}
        >
          <Link to="/">
            <div //className='hera-fem-login'
              style={{
                zIndex: 1,
                backgroundImage: "url(images/hera-fem-logo.png)",
                backgroundRepeat: 'no-repeat',
                backgroundSize: '40% auto',
                minWidth: '277px',
                minHeight: '124px',
                backgroundPositionX: '50%',
                backgroundPositionY: '50%',
                //position: 'relative',
                //bottom: '0px'
              }}
            />
          </Link>
          <Typography
            sx={{
              marginBottom: '10px',
              fontSize: '50px',
              background: "linear-gradient(90deg, rgba(142,212,227,1) 30%, rgba(169,61,155,1) 69%, rgba(253,140,47,1) 100%)",
              WebkitBackgroundClip: 'text',
              WebkitTextFillColor: 'transparent',
              textAlign: 'center',
              borderRadius: '5px 5px 0px 0px',
              lineHeight: '80px',
            }}
          >
            {translate('Welcome_back_login')}
          </Typography>
          <Typography 
            style= {{
                letterSpacing: '2px',
                //textDecoration: 'none',
                color: '#a93d9b',
                textAlign: 'center',
                margin: '12px 0px 22px 0px',
                fontSize: '18px',
                fontWeight: 'bold',
              }}
          >{translate('Enter_new_Password')}</Typography>
            <div
              style={{width: "100%", color: "red"}}
            >
            {appErr}
            </div>
            <div
              style={{width: "100%", color: "red"}}
            >
            {serverErr? "El token no es válido": ""}
            </div>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              backgroundPositionX: '50%',
              backgroundPositionY: '50%',
              alignItems: 'center',
              width: '100%',
            }}
          >
            <Formik
              initialValues ={{
                ...INITIAL_FORM_STATE
              }}
        
              validationSchema ={FORM_VALIDATION}
              onSubmit={values  => handleSubmit(values)}
            >
            {(props: FormikProps<IValues>) => (
              <Form>
                <Textfield
                  sx={{
                    margin: '10px 0px',
                  }}
                  name='password'
                  type='password'
                  label={translate('New_Password')}
                  variant="filled"
                />
                <Textfield
                  sx={{
                    margin: '10px 0px',
                  }}
                  name='newpassword'
                  type='password'
                  label={translate('Confirm_New_Password')}
                  variant="filled"
                />
                <Button
                  sx={{
                    marginTop: '20px',
                    color:"white",
                    backgroundImage: "linear-gradient(to right, #a93d9b 0%, #290d80 100%)"
                  }}
                  disabled={props.values.newpassword !== props.values.password}
                >
                  {translate('Reset_Password')}
                </Button> 
              </Form>
            )}
            </Formik>
            <Link to="/login"
              onClick={setAppErrorUndefined}
              style={{
                letterSpacing: '2px',
                textDecoration: 'none',
                color: '#a93d9b',
                textAlign: 'center',
                margin: '12px 0px 22px 0px',
                cursor: 'pointer',
                fontSize: '14px',
                fontWeight: 'bold',
              }}
            >

               {translate('Back_to_Login')}
            </Link>
          </Box>
          <Link to="/">
            <div
              style={{
                zIndex: 1,
                backgroundImage: "url(images/hera-diag-logo.png)",
                backgroundRepeat: 'no-repeat',
                backgroundSize: '35% auto',
                minWidth: '277px',
                minHeight: '124px',
                backgroundPositionX: '50%',
                backgroundPositionY: '50%',
                //position: 'relative',
                //bottom: '0px'
              }}
            ></div>
          </Link>
        </Box>
      </Box>
    </>
  );
};

export default withLocalize(ChangePassword);
