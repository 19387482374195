import React, {useState, useEffect} from "react";
import { withLocalize } from 'react-localize-redux';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import { doctorLoginFromStorage } from "../../../redux/slices/doctors/doctorSlices";
import config from "config";
import DoctorPatientForm, {IPatientFormState, INITIAL_PATIENT_FORM_STATE} from "./DoctorPatientForm";
import { createPatientAction } from "../../../redux/slices/doctors/patientsSlices";
import {RootState, AppDispatch} from '../../../redux/store/store';

interface IState {
}

interface IProps {
  translate: any;
}

const defaultStateCountry: any =  {
  profile: { address: {
    ...INITIAL_PATIENT_FORM_STATE.profile.address,
    state: config.mexicoStates.indexOf("Nuevo León"),
    country: config.country.indexOf("Mexico")
  }}};

const INITIAL_FORM_STATE: IPatientFormState = {
  ...INITIAL_PATIENT_FORM_STATE, ...defaultStateCountry
};

const DoctorPatientNew: React.FC<IProps> = ({translate}) => {
  const [showing, setShowing] = useState(false);
  const {access_token} =JSON.parse(JSON.stringify(doctorLoginFromStorage));

  const dispatch: AppDispatch = useDispatch();

  const store: any = useSelector((state: RootState) => state?.patients);
  const {created, calledList, loading, serverErr, appErr} = store;
  const navigate = useNavigate();

  const handleSubmit = (values: any) =>{
    //console.log("NEW VALS", values);
      //dispath the action
    const { active, ...otherValues} = values;
    const tokenUser = {
      patient: otherValues,
      access_token
    };

    setShowing(true);
    dispatch(createPatientAction(tokenUser) as any);
  };

  useEffect(() => {

    if (loading === false && created && showing) {
      //console.log("CREATED", created)
      navigate(`/doctor/patients/${created.patient._id}`);
    }
  }, [loading, created, navigate, showing])

  if (loading === true) {
    return <div>Loading...</div>
  };

  const TODO= {
    backgroundColor: '#ffffff', //'yellow',
    maxWidth: "340px",
    margin: 'auto',
  };

  return (
    <div style = {TODO}>
      <div style={{color: "red"}}>{appErr}</div>
      <div style={{color: "red"}}>{serverErr}</div>
      <DoctorPatientForm
        initialState = {INITIAL_FORM_STATE}
        translate= {translate}
        handleSubmit = {handleSubmit}
      />
    </div>
  );
};

export default withLocalize(DoctorPatientNew);
