import React  from 'react';
import { withLocalize } from 'react-localize-redux';
import { Link } from 'react-router-dom';

const Home = (props: any) => {
  
  return(
    <>
      <div>Home Page</div>
      <div>
        {props.translate('We_sent_an_email', {xx: "sent@email.com"})} 
      </div>
      <Link to='/login'>Login</Link>
    </>
  );
};

export default withLocalize(Home);
