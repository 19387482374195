import React from 'react'
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  FormHelperText,
} from '@mui/material';
import { useField, useFormikContext } from 'formik';

const CheckboxWrapper = ({
  name,
  label,
  legend,
  ...otherProps
}: any) => {

  const {setFieldValue} = useFormikContext();
  const [field, meta] = useField(name);

  const handleChange = (evt: any) => {
    const {checked} =  evt.target;
    setFieldValue(name, checked);
  };

  const configCheckbox = {
    checked: field.value, 
    ...field,
    ...otherProps,
    onChange: handleChange,
  };

  const configFormControl = {
    fullWidth: true,
    ...otherProps,
  };

  if (meta && meta.touched && meta.error) {
    configFormControl.error = true;
  }

  return (
    <FormControl {...configFormControl} >
      <FormLabel component="legend">
        { legend }
      </FormLabel>
      <FormGroup>
        <FormControlLabel
          control={<Checkbox {...configCheckbox} />}
          label={label}
        />
      </FormGroup>
      <FormHelperText> {meta.error} </FormHelperText>
    </FormControl>
  );

};

export default CheckboxWrapper;
