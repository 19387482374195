import React, {useState, useEffect} from "react";
import { withLocalize } from 'react-localize-redux';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import { doctorLoginFromStorage } from "../../../redux/slices/doctors/doctorSlices";
import config from "config";
import AdminDoctorForm, {IDoctorFormState, INITIAL_DOCTOR_FORM_STATE} from "./AdminDoctorForm";
import { createUserAction } from "../../../redux/slices/doctors/usersSlices";
import {RootState, AppDispatch} from '../../../redux/store/store';

interface IState {
  name: string;
  email: string;
  tax_id: string;
  practitioner_id: string;
}

interface IProps {
  translate: any;
}

const defaultStateCountry: any =  {
  clinic: { address: {
    ...INITIAL_DOCTOR_FORM_STATE.clinic.address,
    state: config.mexicoStates.indexOf("Nuevo León"),
    country: config.country.indexOf("Mexico")
  }}};
// /doctors/:doctor_id/detail
const INITIAL_FORM_STATE: IDoctorFormState = {
  ...INITIAL_DOCTOR_FORM_STATE, ...defaultStateCountry
};

const AdminDoctorNew: React.FC<IProps> = ({translate}) => {
  const [showing, setShowing] = useState(false);
  const {access_token} =JSON.parse(JSON.stringify(doctorLoginFromStorage));

  const dispatch: AppDispatch = useDispatch();

  const store: any = useSelector((state: RootState) => state?.users);
  const {created, registered, loading, serverErr, appErr} = store;
  const navigate = useNavigate();

  const handleSubmit= (values: any) => {
      //dispath the action
    const { confirm_password, ...otherValues} = values;
    const tokenUser = {
      doctor: otherValues,
      access_token
    };
    setShowing(true);
    dispatch(createUserAction(tokenUser) as any);
  };

  useEffect(() => {

    if (loading === false && created && showing) {
      //console.log("DOCTOR ", created);
      // redirect to 
      navigate(`/admin/doctors/${created._id}`);
    }
  }, [loading, created, navigate, showing]);

  if (loading === true) {
    return <div>Loading...</div>
  };

  const TODO= {
    backgroundColor: '#ffffff', //'yellow',
    maxWidth: "340px",
    margin: 'auto',
  };

  return (
    <div style = {TODO}>
      <div style={{color: "red"}}>{appErr}</div>
      <div style={{color: "red"}}>{serverErr}</div>
      <AdminDoctorForm
        initialState = {INITIAL_FORM_STATE}
        translate= {translate}
        handleSubmit = {handleSubmit}
      />
    </div>
  );
};

export default withLocalize(AdminDoctorNew);
