import { configureStore } from "@reduxjs/toolkit";
import { useDispatch } from 'react-redux';
import doctorsReducer from "../slices/doctors/doctorSlices";
import usersReducer from "../slices/doctors/usersSlices";
import patientsReducer from "../slices/doctors/patientsSlices";
import settingsReducer from "../slices/doctors/settingsSlices";
import macsReducer from "../slices/doctors/macsSlices";
import examsReducer from "../slices/doctors/examsSlices";
import { localizeReducer } from 'react-localize-redux';

const store = configureStore({
  reducer: {
    localize: localizeReducer as any,
    doctors: doctorsReducer,
    settings: settingsReducer,
    users: usersReducer,
    macs: macsReducer,
    exams: examsReducer,
    patients: patientsReducer,
  },
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export const useAppDispatch: () => AppDispatch = useDispatch;

export default store;
