//import  language from 'languagesp';
import React from 'react';
import { Box, Typography } from '@mui/material';
import Textfield from '../../FormsUI/Textfield';
import Button from '../../FormsUI/Button';
import { withLocalize } from 'react-localize-redux';
import { Link } from 'react-router-dom'
import { Formik, Form } from "formik";
import { Navigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import { appErrUndefinedAction, loginDoctorAction } from "../../../redux/slices/doctors/doctorSlices";
import {RootState, AppDispatch} from '../../../redux/store/store';

//import './Login.scss'

interface IProps {
  translate: any;
  languages: any;
}

interface IState {
}

const INITIAL_FORM_STATE = {
  email: "",
  password: "",
};

//Form schema
const FORM_VALIDATION = Yup.object().shape({
   email: Yup.string().required("Email is required"),
   password: Yup.string().required("Password is required"),
});

const Login: React.FC<IProps>= ({translate, languages}) => {
  let language;
  for (let i = 0; i< languages.length; i++) {
    if (languages[i].active) {
      language = languages[i].code;
    }
  }

  const dispatch: AppDispatch = useDispatch();

  const setAppErrUndefined = () => {
    dispatch(appErrUndefinedAction());
  };

  const handleSubmit= (values: any) => {
      //dispath the action
    //console.log(values);
    dispatch(loginDoctorAction(values) as any);
  };

  // redirect
  const store: any = useSelector((state: RootState) => state?.doctors);
  const {registered, doctorAuth, loading, serverErr, appErr} = store;
  if (doctorAuth) {
    if (doctorAuth.admin) {
      return <Navigate replace={true} to="/admin" />
    } else {
      return <Navigate replace={true} to="/doctor" />
    }
  }

  const TODO= {
    backgroundColor: '#ffff77', //'yellow',
    maxWidth: "320px",
    margin: 'auto',
  };

  return (
    <>
      <Box
        sx={TODO}
      >
        <Box //className='login-container'
          sx={{
            //height: 'calc(100vh - 48px)',
          }}
        >
          <Link to="/">
            <div //className='hera-fem-login'
              style={{
                zIndex: 1,
                backgroundImage: "url(images/hera-fem-logo.png)",
                backgroundRepeat: 'no-repeat',
                backgroundSize: '40% auto',
                minWidth: '277px',
                minHeight: '124px',
                backgroundPositionX: '50%',
                backgroundPositionY: '50%',
                //position: 'relative',
                //bottom: '0px'
              }}
            />
          </Link>
            <div
              style={{width: "100%", color: "green"}}
            >
            {registered?.message}
            </div>
          <Typography
            sx={{
              marginBottom: '10px',
              fontSize: '50px',
              background: "linear-gradient(90deg, rgba(142,212,227,1) 30%, rgba(169,61,155,1) 69%, rgba(253,140,47,1) 100%)",
              WebkitBackgroundClip: 'text',
              WebkitTextFillColor: 'transparent',
              textAlign: 'center',
              borderRadius: '5px 5px 0px 0px',
              lineHeight: '80px',
            }}
          >
            {translate('Welcome_back_login')}
          </Typography>

            <div
              style={{width: "100%", color: "red"}}
            >
            {appErr}
            </div>
          <Formik
            initialValues ={{
              ...INITIAL_FORM_STATE
            }}
      
            validationSchema ={FORM_VALIDATION}
            onSubmit={values => handleSubmit(values)}
          >
            <Form
              style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                backgroundPositionX: '50%',
                backgroundPositionY: '50%',
                alignItems: 'center',
                width: '100%',
              }}
              //className='login-form'
            >
              <Textfield
                sx={{ margin: '10px 0px'}}
                name='email'
                type='email'
                label={translate('Email_Address')}
                variant="filled"
              />
              <Textfield
                sx={{ margin: '10px 0px'}}
                name='password'
                type='password'
                label={translate('Password')}
                variant="filled"
              />
              <Link to="/forgot-password"
                onClick={setAppErrUndefined}
                style={{
                  letterSpacing: '2px',
                  textDecoration: 'none',
                  color: '#a93d9b',
                  textAlign: 'center',
                  margin: '12px 0px 22px 0px',
                  cursor: 'pointer',
                  fontSize: '14px',
                  fontWeight: 'bold',
                }}
              >
                 {translate('Reset_Password')}
              </Link>
              <Button
                sx= {{ backgroundImage: "linear-gradient(to right, #a93d9b 0%, #290d80 100%)"}}
              >
                {translate('Login')}
              </Button> 
            </Form>
          </Formik>
          <Link to="/">
            <div
              style={{
                zIndex: 1,
                backgroundImage: "url(images/hera-diag-logo.png)",
                backgroundRepeat: 'no-repeat',
                backgroundSize: '35% auto',
                minWidth: '277px',
                minHeight: '124px',
                backgroundPositionX: '50%',
                backgroundPositionY: '50%',
              }}
            ></div>
          </Link>
        </Box>
      </Box>
    </>
  );
};

export default withLocalize(Login);
