import React, {useState, useEffect} from "react";
import { withLocalize } from 'react-localize-redux';
import { useParams, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import { doctorLoginFromStorage } from "../../../redux/slices/doctors/doctorSlices";
import config from "config";
import AdminDoctorForm, {IDoctorFormState, INITIAL_DOCTOR_FORM_STATE} from "./AdminDoctorForm";
import { updateUserAction } from "../../../redux/slices/doctors/usersSlices";
import {RootState, AppDispatch} from '../../../redux/store/store';
import { getOneUserAction } from "../../../redux/slices/doctors/usersSlices";

interface IState {
}

interface IProps {
  translate: any;
}

const defaultStateCountry: any =  {
  clinic: { address: {
    ...INITIAL_DOCTOR_FORM_STATE.clinic.address,
    state: config.mexicoStates.indexOf("Nuevo León"),
    country: config.country.indexOf("Mexico")
  }}};
const INITIAL_FORM_STATE: IDoctorFormState = {
  ...INITIAL_DOCTOR_FORM_STATE, ...defaultStateCountry
};

const AdminDoctorEdit: React.FC<IProps> = ({translate}) => {
  const [doctor, setDoctor] = useState({...INITIAL_FORM_STATE})

  const {id} = useParams();

  const {access_token} =JSON.parse(JSON.stringify(doctorLoginFromStorage));

  const dispatch: AppDispatch = useDispatch();


  const store: any = useSelector((state: RootState) => state?.users);
  const {called, updated, registered, loading, serverErr, appErr} = store;
  const [showing, setShowing] = useState(false);
  const navigate = useNavigate();

  const handleSubmit= (values: any) => {
      //dispath the action
    const { confirm_password, ...otherValues} = values;
    const tokenUser = {
      doctor: otherValues,
      access_token
    };
    setShowing(true);
    dispatch(updateUserAction(tokenUser) as any);
  };

  useEffect(() => {
    const token = {
      access_token,
      user_id: id
    };

    dispatch(getOneUserAction(token));
    
  }, [access_token, dispatch, id]); // ?? []

  useEffect(() => {

    if (loading === false && updated && showing) {
      navigate(`/admin/doctors/${id}`);
    }
  }, [loading, updated, id, navigate, showing]);

  useEffect(() => {

    if (loading === false && called) {
      const { doctor } = called;
      const { password, ...other} = doctor;
      setDoctor(other);
    }
  }, [loading, called],)

  if (loading === true || !doctor) {
    return <div>Loading...</div>
  };

  const TODO= {
    backgroundColor: '#ffffff', //'yellow',
    maxWidth: "340px",
    margin: 'auto',
  };

  return (
    <div style = {TODO}>
      <div style={{color: "red"}}>{appErr}</div>
      <div style={{color: "red"}}>{serverErr}</div>
      <AdminDoctorForm
        initialState = {{...INITIAL_DOCTOR_FORM_STATE, ...doctor}}
        translate= {translate}
        handleSubmit = {handleSubmit}
      />
    </div>
  );
};

export default withLocalize(AdminDoctorEdit);
