import React, {useState, useEffect} from "react";
import { withLocalize } from 'react-localize-redux';
import { useParams, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import Button from '../../FormsUI/Button';
import Select from "../../FormsUI/Select";
import { doctorLoginFromStorage } from "../../../redux/slices/doctors/doctorSlices";
import { updateExamAction } from "../../../redux/slices/doctors/examsSlices";
import {RootState, AppDispatch} from '../../../redux/store/store';
import { getOneExamAction } from "../../../redux/slices/doctors/examsSlices";
import { getPatientsAction } from "../../../redux/slices/doctors/patientsSlices";
import { Formik, Form,} from "formik";


interface IProps {
  translate: any;
}

const INITIAL_STATE = {}

const DoctorExamEdit: React.FC<IProps> = ({translate}) => {
  const [exam, setExam] = useState({...INITIAL_STATE})
  const [options, setOptions] = useState([]);
  const [defaultOptionId, setDefaultOptionId] = useState("");
  const [defaultOptionIndex, setDefaultOptionIndex] = useState(-1);

  const {id} = useParams();

  const {access_token} =JSON.parse(JSON.stringify(doctorLoginFromStorage));

  const dispatch: AppDispatch = useDispatch();

  const store: any = useSelector((state: RootState) => state);
  const {called, updated, loading, serverErr, appErr} = store.exams;
  const {calledList} = store.patients;
  const [showing, setShowing] = useState(false);
  const navigate = useNavigate();

  const handleSubmit= (values: any, onSubmitProps: any) => {
    if (values.patient >= 0) {
      const patient_id = Object.keys(options[values.patient])[0];
        //dispath the action
      console.log("VALUES ", patient_id);
      console.log("EXAM H", exam);

      // exam_id ??
      const tokenExam = {
        exam: {...exam, patient: patient_id, _id: id},
        access_token
      };
      setShowing(true);
      dispatch(updateExamAction(tokenExam) as any);
    }

  };

  useEffect(() => {
    const token = {
      access_token,
      exam_id: id
    };

    dispatch(getPatientsAction(token));
    dispatch(getOneExamAction(token));
    
  }, [access_token, dispatch, id]); // ?? []

  useEffect(() => {

    if (loading === false && updated && showing) {
      navigate(`/doctor/exams/${id}`);
    }
  }, [loading, updated, id, navigate, showing])


  useEffect(() => {

    if (loading === false && calledList) {

      const options = calledList.patients.map((elem: any) => {
        if (elem.exams === id) {
          setDefaultOptionId(elem._id);
        }
        const option: any = {};
        option[elem._id] = `${elem.profile.name.first} ${elem.profile.name.last}`;
        return option;
      });

      setOptions(options);

    }
  }, [loading, calledList, id ])

  useEffect(() => {
    const optionIndex = options.findIndex((object : any) => defaultOptionId in object);
    setDefaultOptionIndex(optionIndex);
  }, [options, defaultOptionId]);

  useEffect(() => {
    if (loading === false && called) {
      const { exams } = called;
      setExam({...exams});
    }
  }, [loading, called]);

  if (loading === true || !calledList || !called) {
    return <div>Loading...</div>
  };

  const TODO= {
    backgroundColor: '#ffffff', //'yellow',
    maxWidth: "340px",
    margin: 'auto',
  };

  return (
    <div style = {TODO}>
      <div style={{color: "red"}}>{appErr}</div>
      <div style={{color: "red"}}>{serverErr}</div>

      <Formik
        enableReinitialize
        initialValues ={{patient: defaultOptionIndex }} 
  
        onSubmit={(values: any, {setSubmitting, resetForm}) => {
          setSubmitting(true);
          handleSubmit(values, values);
          // resetForm();
          setSubmitting(false);
          //console.log("SUBMIT VALS -> ", values);
        }}
      >
        <Form
          style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              backgroundPositionX: '50%',
              backgroundPositionY: '50%',
              alignItems: 'center',
              width: '100%',
              marginTop: '20px',
          }}
        >
          <Select
            style={{marginBottom: 10}}
            name ="patient"
            label={translate("Patient")}
            placeholder={translate("Patient")}
            options={options.map((elem: any) => Object.values(elem)[0])}
            variant="filled"
          />
          <Button
            type="submit"
            disabled={ options.length < 1 }
            sx= {{
              backgroundImage: "linear-gradient(to right, #a93d9b 0%, #290d80 100%)"}}
          >
            {translate('Save_Changes')}
          </Button> 
        </Form>
      </Formik>
    </div>
  );
};

export default withLocalize(DoctorExamEdit);

