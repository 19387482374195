import React, { useState, useEffect } from 'react';
import { withLocalize } from 'react-localize-redux';
import { Typography, Grid, Table, TablePagination, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Button, TextField } from '@mui/material';
import { useDispatch, useSelector } from "react-redux";
import { doctorLoginFromStorage } from "../../../redux/slices/doctors/doctorSlices";
import { getMacsAction } from "../../../redux/slices/doctors/macsSlices";
import {RootState, AppDispatch} from '../../../redux/store/store';
import { Link } from 'react-router-dom';

// TODO: change mac data
function createData(
    _id: string,
    mac: string,
    active: string,

) {
    return { _id, mac, active};
}

interface IProps {
  translate: any;
}

// TODO: update interface to macs data
interface IState {
  _id: string;
  active: boolean;
  mac: string;
}

// TODO: update to macs data initial state
const INITIAL_STATE: IState[] = [{
  _id:'6375221a1870d7271834f342',
  active: false,
  mac: '11:22:33:44:55:66'
},];

const AdminMacs: React.FC<IProps> = ({translate}) =>  {
  const [searchTerm, setSearchTerm] = useState('');
  const [rows, setRows] = useState([...INITIAL_STATE, ]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  //const {id} = useParams();

  const {access_token} =JSON.parse(JSON.stringify(doctorLoginFromStorage));

  const dispatch: AppDispatch = useDispatch();

  const handleChangeRowsPerPage = (event: any) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  useEffect(() => {
    const token = {
      access_token,
    };

    dispatch(getMacsAction(token));
    
  }, [access_token, dispatch]); // ?? []

  const store: any = useSelector((state: RootState) => state?.macs);
  const {calledList, loading, serverErr, appErr} = store;

  useEffect(() => {

    if (loading === false && calledList) {
      console.log("CALLED ", calledList);
      const { devices } = calledList;
      console.log("DEVICES ", devices);

      const newRows = devices.map((device: any) => createData(device._id, device.mac, device.active));
      setRows(newRows);
    }
  }, [loading, calledList],)

  const handleChangePage = (_:any, newPage: any) => {
    setPage(newPage);
  };

  const filteredData = rows.filter((row) => {
    const { mac } = row;
    const lowerCaseSearchTerm = searchTerm.toLowerCase();
    return mac?.toLowerCase().includes(lowerCaseSearchTerm);
  });

  const renderTableRows = () => {
    const startIndex = page * rowsPerPage;
    const endIndex = startIndex + rowsPerPage;
    return filteredData.slice(startIndex, endIndex).map((row) => (

      <TableRow key={row._id}>
        <TableCell>{row.mac}</TableCell>
        <TableCell>{(row.active)? translate("Device_Active"): translate("Device_Inactive")}</TableCell>
        <TableCell>
          <Link to={`/admin/macs/${row._id}`}>{translate("View").toUpperCase()}</Link>
        </TableCell>
        <TableCell>
          <Link to={`/admin/macs/${row._id}/edit`}>{translate("Edit").toUpperCase()}</Link>
        </TableCell>
        <TableCell>
          <Button color="secondary">{translate("Delete")}</Button>
        </TableCell>
      </TableRow>
    ));
  };

  if (loading === true) {
    return <div>Loading...</div>
  };

  return (
    <>
      <div style={{color: "red"}}>{appErr}</div>
      <div style={{color: "red"}}>{serverErr}</div>
      <Grid container spacing={2} sx={{margin: '10px'}}>
        <Grid item xs={12} md={8}>
          <Typography sx={{fontSize: 24}}>{translate("Device_Database")}</Typography>
        </Grid>
        <Grid item>
          <TextField
            label= {`${translate("Search_Devices")}`}
            variant="outlined"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
        </Grid>
      </Grid>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>{translate("Device_MAC")}</TableCell>
              <TableCell>{translate("Device_Status")}</TableCell>
              <TableCell>{translate("View")}</TableCell>
              <TableCell>{translate("Edit")}</TableCell>
              <TableCell>{translate("Delete")}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {renderTableRows()}
          </TableBody>
        </Table>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={filteredData.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </TableContainer>
      <Link to={`/admin/macs/new`}>{translate("Add_Device_MAC")}</Link>
    </>
  );
};

// TO DO
// }
// {translate('No_Macs_Available')}

export default withLocalize(AdminMacs) ;

