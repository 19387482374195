import React, { useState, useEffect } from 'react';
import { withLocalize } from 'react-localize-redux';
import {
  Typography, Grid, Table, TablePagination, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Button, TextField 
} from '@mui/material';
import { useDispatch, useSelector } from "react-redux";
import { doctorLoginFromStorage } from "../../../redux/slices/doctors/doctorSlices";
import { getExamsAction } from "../../../redux/slices/doctors/examsSlices";
import {RootState, AppDispatch} from '../../../redux/store/store';
import { Link } from 'react-router-dom';

function createData(
    _id: string,
    mac: string,
    timestamp: string,
    patient: string,
) {
    return { _id, mac, timestamp, patient};
}

interface IProps {
  translate: any;
}

interface IState {
  _id: string;
  mac: string;
  timestamp: string;
  patient: string;
}

const INITIAL_STATE: IState[] = [{
  _id:'',
  mac: '',
  timestamp: '',
  patient: ''
},];

const DoctorExams: React.FC<IProps> = ({translate}) =>  {
  const [searchTerm, setSearchTerm] = useState('');
  const [rows, setRows] = useState([...INITIAL_STATE, ]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const {access_token} =JSON.parse(JSON.stringify(doctorLoginFromStorage));

  const dispatch: AppDispatch = useDispatch();

  const handleChangeRowsPerPage = (event: any) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  useEffect(() => {
    const token = {
      access_token
    };

    dispatch(getExamsAction(token));
    
  }, [access_token, dispatch]); // ?? []

  const store: any = useSelector((state: RootState) => state?.exams);
  const {calledList, loading, serverErr, appErr} = store;

  useEffect(() => {

    if (loading === false && calledList) {
      const { exams } = calledList;

      //console.log("EXAMS", exams);
      //console.log("EXAMS", exams[0].doctor._id);

      const newRows = exams.map((exam: any) => { 
        let patient;
        if (exam.patient) {
          const {name} = exam.patient.profile;
          patient = name.first + ' ' + name.last;
        } else {
          patient = "Not assigned"
        }

        return createData(exam._id, exam.device.mac, exam.data.TS, patient)
      });

      newRows.sort((a: any, b: any) => {
        let dateA = new Date(a.timestamp.split(' ')[0] + 'T' + a.timestamp.split(' ')[1]);
        let dateB = new Date(b.timestamp.split(' ')[0] + 'T' + b.timestamp.split(' ')[1]);
        return dateB.getTime() - dateA.getTime();
      });

      setRows(newRows);
    }
  }, [loading, calledList],)

  const handleChangePage = (_:any, newPage: any) => {
    setPage(newPage);
  };

  const filteredData = rows.filter((row) => {
    const { mac, timestamp } = row;
    const lowerCaseSearchTerm = searchTerm.toLowerCase();
    return mac?.toLowerCase().includes(lowerCaseSearchTerm);
  });

  const renderTableRows = () => {
    const startIndex = page * rowsPerPage;
    const endIndex = startIndex + rowsPerPage;
    return filteredData.slice(startIndex, endIndex).map((row) => (

      <TableRow key={row._id}>
        <TableCell>{row.mac}</TableCell>
        <TableCell>{row.timestamp}</TableCell>
        <TableCell>{row.patient}</TableCell>
        <TableCell>
          <Link to={`/doctor/exams/${row._id}`}>{translate("View").toUpperCase()}</Link>
        </TableCell>
        <TableCell>
          <Link to={`/doctor/exams/${row._id}/edit`}>{translate("Edit").toUpperCase()}</Link>
        </TableCell>
        <TableCell>
          <Button color="secondary">{translate("Delete")}</Button>
        </TableCell>
      </TableRow>
    ));
  };

  if (loading === true) {
    return <div>Loading...</div>
  };

  return (
    <>
      <div style={{color: "red"}}>{appErr}</div>
      <div style={{color: "red"}}>{serverErr}</div>
      <Grid container spacing={2} sx={{margin: '10px'}}>
        <Grid item xs={12} md={8}>
          <Typography sx={{fontSize: 24}}>{translate("Instapap_Database")}</Typography>
        </Grid>
        <Grid item>
          <TextField
            label= {`${translate("Search_Devices")}`}
            variant="outlined"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
        </Grid>
      </Grid>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>{translate("Device_MAC")}</TableCell>
              <TableCell>{translate("Test_Date")}</TableCell>
              <TableCell>{translate("Patient")}</TableCell>
              <TableCell>{translate("View")}</TableCell>
              <TableCell>{translate("Edit")}</TableCell>
              <TableCell>{translate("Delete")}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {renderTableRows()}
          </TableBody>
        </Table>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={filteredData.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </TableContainer>
      <Link to="/doctor/exams/new">{translate('Upload_File')}</Link>
    </>
  );
};

// TO DO
// }
// {translate('No_Doctors_Available')}

export default withLocalize(DoctorExams) ;

