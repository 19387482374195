import React, { useState } from 'react';

interface JsonreaderProps {
  onChange: (value: string) => void;
}

const Jsonreader: React.FC<JsonreaderProps> = ({ onChange }) => {

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.currentTarget.files?.[0];
    if (file) {
      const reader = new FileReader();
      reader.readAsText(file, 'UTF-8');
      reader.onload = (e) => {
        onChange(e.target?.result as string);
      };
    }
  };

  return (
    <input type="file" onChange={handleChange} accept='.json'/>
  );
};

export default Jsonreader;
