import React from "react";
import {BrowserRouter, Routes, Route } from "react-router-dom";
import Navbar from "./Navigation/Navbar";
import AdminRoute from "./Navigation/ProtectedRoutes/AdminRoute";
import DoctorRoute from "./Navigation/ProtectedRoutes/DoctorRoute";
import Home from "./pages/Home";
import DoctorPatients from "./pages/Doctor/DoctorPatients";
import DoctorPatientNew from "./pages/Doctor/DoctorPatientNew";
import DoctorPatientShow from "./pages/Doctor/DoctorPatientShow";
import DoctorPatientEdit from "./pages/Doctor/DoctorPatientEdit";
import DoctorExams from "./pages/Doctor/DoctorExams";
import DoctorExamNew from "./pages/Doctor/DoctorExamNew";
import DoctorExamShow from "./pages/Doctor/DoctorExamShow";
import DoctorExamEdit from "./pages/Doctor/DoctorExamEdit";
import AdminDoctors from "./pages/Admin/AdminDoctors";
import AdminDoctorNew from "./pages/Admin/AdminDoctorNew";
import AdminDoctorShow from "./pages/Admin/AdminDoctorShow";
import AdminDoctorEdit from "./pages/Admin/AdminDoctorEdit";
import AdminDoctorMacs from "./pages/Admin/AdminDoctorMacs";
import AdminDoctorMacNew from "./pages/Admin/AdminDoctorMacNew";
import AdminDoctorPatients from "./pages/Admin/AdminDoctorPatients";
import AdminSettings from "./pages/Admin/AdminSettings";
import AdminMacs from "./pages/Admin/AdminMacs";
import AdminMacNew from "./pages/Admin/AdminMacNew";
import AdminMacShow from "./pages/Admin/AdminMacShow";
import AdminMacEdit from "./pages/Admin/AdminMacEdit";
import Login from "./pages/Login";
import ForgotPassword from "./pages/Login/ForgotPassword";
import ChangePassword from "./pages/Login/ChangePassword";

function App() {
  return (
    <BrowserRouter>
      <Navbar />
      <Routes>
        <Route element={<AdminRoute/>}>
          <Route path="/admin" element={<AdminDoctors/>} />
          <Route path="/admin/doctors" element={<AdminDoctors/>} />
          <Route path="/admin/settings" element={<AdminSettings/>} />
          <Route path="/admin/doctors/new" element={<AdminDoctorNew/>} />
          <Route path="/admin/doctors/:id" element={<AdminDoctorShow/>} />
          <Route path="/admin/doctors/:id/edit" element={<AdminDoctorEdit/>} />
          <Route path="/admin/doctors/:id/macs" element={<AdminDoctorMacs/>} />
          <Route path="/admin/doctors/:id/macs/new" element={<AdminDoctorMacNew/>} />
          <Route path="/admin/doctors/:id/patients" element={<AdminDoctorPatients/>} />
          <Route path="/admin/macs" element={<AdminMacs/>} />
          <Route path="/admin/macs/new" element={<AdminMacNew/>} />
          <Route path="/admin/macs/:id" element={<AdminMacShow/>} />
          <Route path="/admin/macs/:id/edit" element={<AdminMacEdit/>} />
        </Route>
        <Route element={<DoctorRoute/>}>
          <Route path="/doctor" element={<DoctorPatients/>} />
          <Route path="/doctor/patients" element={<DoctorPatients/>} />
          <Route path="/doctor/patients/new" element={<DoctorPatientNew/>} />
          <Route path="/doctor/patients/:id" element={<DoctorPatientShow/>} />
          <Route path="/doctor/patients/:id/edit" element={<DoctorPatientEdit/>} />
          <Route path="/doctor/exams" element={<DoctorExams/>} />
          <Route path="/doctor/exams/new" element={<DoctorExamNew/>} />
          <Route path="/doctor/exams/:id" element={<DoctorExamShow/>} />
          <Route path="/doctor/exams/:id/edit" element={<DoctorExamEdit/>} />
        </Route>
        <Route path="/" element={<Home />} />
        <Route path="/login" element={<Login />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route path="/resetpassword" element={<ChangePassword />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
