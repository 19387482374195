//import language from 'languagesp';
import React, {useState, useEffect}  from "react";
import { Input, Box, Typography } from '@mui/material';
//import Filefield from '../../FormsUI/Filefield';
import Jsonfield from '../../FormsUI/Jsonfield';
import Button from '../../FormsUI/Button';
import { withLocalize } from 'react-localize-redux';
import { Formik, Form, Field } from "formik";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import { doctorLoginFromStorage } from "../../../redux/slices/doctors/doctorSlices";
import { createExamAction} from "../../../redux/slices/doctors/examsSlices";
import {RootState, AppDispatch} from '../../../redux/store/store';

interface IProps {
  translate: any;
  languages: any;
}

interface IState {
  file: string | "{}"; 
}

const INITIAL_FORM_STATE: IState = {
  file: "",
};

function formatMacAndTimestamp(mac: string, timestamp: string): string {
  let formattedMac = mac.replace(/:/g, '');
  let dateAndTime = timestamp.split(' ');
  let formattedTimestamp = (dateAndTime[0] + dateAndTime[1]).replace(/[-:]/g, '');
  return `${formattedMac}_${formattedTimestamp}`;
}

const FORM_VALIDATION = Yup.object().shape({
  file: Yup.string()
  .required("Valid exam is required")
  /*
  .test(
    'fileType',
    'Invalid file type. Only JSON files are allowed.',
    (value) => {
      if (!value) {
        return false;
      }
      const fileType = value[0].name.split('.').pop();
      return fileType === 'json';
    }
  ),
  */
});

const DoctorExamNew: React.FC<IProps> = ({translate, languages}) => {

  //const [file, setFile] = useState<string | "">();
  //const [exam, setExam] = useState<string>("");
  const [message, setMessage] = useState<string>("");
  let language;
  for (let i = 0; i < languages.length; i++) {
    if (languages[i].active) {
      language = languages[i].code;
    }
  }

  const {access_token} =JSON.parse(JSON.stringify(doctorLoginFromStorage));

  const dispatch: AppDispatch = useDispatch();

  useEffect(() => {
    const token = {
      access_token
    };

    //dispatch(getSettingsAction(token));
    
  }, [access_token, dispatch]);


  /*
  const setAppErrUndefined = () => {
    dispatch(appErrUndefinedAction());
  };
  */

  const handleSubmit= (values: any) => {

    //console.log("VAlues ", values);
    //console.log("VAlues.files ", values.file);
    const {exam} = JSON.parse(values.file);
    //console.log(exam);

      //dispath the action
    //token = doctorLoginFromStorage;
    if (exam) {
      //formatMacAndTimestamp(exam.MAC, exam.TS)
      const message_id = formatMacAndTimestamp(exam.MAC, exam.TS);
      const tokenExamMessage = {
        exam,//: JSON.stringify(exam),
        message_id,
        access_token
      };
      setMessage("");
      dispatch(createExamAction(tokenExamMessage) as any);
    }

  };

  // redirect

  // TODO exam instead of settings
  const store: any = useSelector((state: RootState) => state?.exams);
  const {created, loading, serverErr, appErr} = store;

  useEffect(() => {
    if (!loading && created) {
      console.log("created", JSON.stringify(created));
      console.log("Success: ", translate('Created_exam_successfully'));
      setMessage(translate('Created_exam_successfully'));
    }
  }, [loading, created, translate]);

  if (loading === true) {
    return <div>Loading...</div>
  }


  return (
  <>
    <Box
      sx= {{
        maxWidth: "320px",
        margin: 'auto',
      }}
    >
      <div style={{color: "red"}}>{serverErr || appErr}</div>
      <div style={{color: "green"}}>{message}</div>
      <Typography
        sx={{
          margin: "20px 0",
          fontSize: 24
        }}
      >
        {translate("Upload_File")}
      </Typography>
      
      <Formik<IState>
        initialValues ={{
          ...INITIAL_FORM_STATE
        }}
  
        validationSchema = {FORM_VALIDATION}
        onSubmit={(values: any,{setSubmitting, resetForm}) => {
          setSubmitting(true);
          handleSubmit(values);
          //resetForm();
          setSubmitting(false);
        }}
      >
      { (formik: any ) => {
      return (
        <>
        <Form>
          <Jsonfield
            name='file'
          />
          <Button
            sx= {{
              marginTop: '20px',
              backgroundImage: "linear-gradient(to right, #a93d9b 0%, #290d80 100%)"}}
            //disabled={exam === ""? true : false}
            //disabled={(!formik.values.file || formik.errors.file) ? true : false}
          >
            {translate('Save_Changes')}
          </Button> 
        </Form>
        </>
      )}}
      </Formik>
    </Box>
  </>
  );
};

export default withLocalize(DoctorExamNew) ;
