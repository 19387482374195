import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { baseUrl } from "../../../utils/baseURL";

const SETTINGS_INFO = "settingsInfo";

export interface SettingsState {
  settingsInfo: any
  loading: boolean;
  appErr: any;
  serverErr: any;
  updated: string;
  called: string;
}

// update settings action
export const getSettingsAction: any = createAsyncThunk(
  "settings/get/settings",
  async (token, {rejectWithValue, getState, dispatch}) => {

    const {access_token} = token as any;
    const config = {
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Basic ${access_token}`
      },
    };

    try {
      const { data } = await axios.get(
        `${baseUrl}/api/v1/settings/app`,
        config
      );
      //localStorage.setItem(SETTINGS_INFO, JSON.stringify(data));
      return data;
    } catch (error: any) {
      if (!error?.response) {
        throw error;
      }
      return rejectWithValue(error?.response?.data);
    }
  }
);

// update settings action
export const updateSettingsAction: any = createAsyncThunk(
  "settings/update/settings",
  async (tokenSettings, {rejectWithValue, getState, dispatch}) => {

    const {_id, validPoints, access_token} = tokenSettings as any;
    const body = {
      settings: {
        _id,
        validPoints
      }
    };
    const config = {
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Basic ${access_token}`
      },
    };

    try {
      const { data } = await axios.put(
        `${baseUrl}/api/v1/settings/app`,
        body,
        config
      );
      return data;
    } catch (error: any) {
      if (!error?.response) {
        throw error;
      }
      return rejectWithValue(error?.response?.data);
    }
  }
);

// get settings from local storage and place into store
const settingsFromStorage: string = localStorage.getItem(SETTINGS_INFO)
  ? JSON.parse(localStorage.getItem(SETTINGS_INFO) || "")
  : null;

const initialState: SettingsState = {
  settingsInfo: settingsFromStorage,
  loading: false,
  appErr: undefined,
  serverErr: undefined,
  updated: "",
  called: ""
};

const doctorSlices = createSlice({
  name: "settings",
  initialState,
  extraReducers: (builder:any) => {
    // appErrUndefined

    // get Settings
    builder.addCase(getSettingsAction.pending, (state: SettingsState, action: any) => {
      state.loading = true;
      state.appErr = undefined;
      state.serverErr = undefined;
    });
    builder.addCase(getSettingsAction.fulfilled, (state: SettingsState, action: any) => {
      state.loading = false;
      state.called = action?.payload;
      state.appErr = undefined;
      state.serverErr = undefined;
    });
    builder.addCase(getSettingsAction.rejected, (state: SettingsState, action: any) => {
      state.loading = false;
      //state.appErr = action?.payload?.message;
      state.appErr = action?.payload?.JsonWebTokenError;
      state.serverErr = action?.error?.message;
    });

    // update settings
    builder.addCase(updateSettingsAction.pending, (state: SettingsState, action: any) => {
      state.loading = true;
      state.appErr = undefined;
      state.serverErr = undefined;
    });
    builder.addCase(updateSettingsAction.fulfilled, (state: SettingsState, action: any) => {
      state.loading = false;
      state.updated = action?.payload;
      state.appErr = undefined;
      state.serverErr = undefined;
    });
    builder.addCase(updateSettingsAction.rejected, (state: SettingsState, action: any) => {
      state.loading = false;
      //state.appErr = action?.payload?.message;
      state.appErr = action?.payload?.JsonWebTokenError;
      state.serverErr = action?.error?.message;
    });

  },
} as any);

export default doctorSlices.reducer;
