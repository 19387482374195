import React, {useState, useEffect} from "react";
import { withLocalize } from 'react-localize-redux';
import { useParams, Link } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import { doctorLoginFromStorage } from "../../../redux/slices/doctors/doctorSlices";
import { getOneUserAction } from "../../../redux/slices/doctors/usersSlices";
import {RootState, AppDispatch} from '../../../redux/store/store';
import config from "config";

interface IState {
  name: string;
  email: string;
  google_calendar: string;
  devices: string[];
  tax_id: string;
  practitioner_id: string;
  clinic: {
    name: string;
    phone: string;
    address: {
      street: string;
      street2: string;
      city: string;
      state: string;
      zip: string;
      country: string;
    }
  }
}

interface IProps {
  translate: any;
}

const INITIAL_STATE: IState = {
  name: "",
  email: "",
  google_calendar: "",
  devices: [],
  tax_id: "",
  practitioner_id: "",
  clinic: {
    name: "",
    phone: "",
    address: {
      street: "",
      street2: "",
      city: "",
      state: "",
      zip: "",
      country: "",
    }
  }
}

const AdminDoctorShow: React.FC<IProps> = ({translate}) => {
  const [doctor, setDoctor] = useState({...INITIAL_STATE})

  const {id} = useParams();

  const {access_token} =JSON.parse(JSON.stringify(doctorLoginFromStorage));

  const dispatch: AppDispatch = useDispatch();

  useEffect(() => {
    const token = {
      access_token,
      user_id: id
    };

    dispatch(getOneUserAction(token));
    
  }, [access_token, dispatch, id]); // ?? []

  const store: any = useSelector((state: RootState) => state?.users);
  const {called, loading, serverErr, appErr} = store;

  const handleSubmit= (values: any) => {
      //dispath the action
    console.log("VALUES ", values);
    /*
    const _id = JSON.parse(JSON.stringify(registered))?.settings?._id;
    //token = doctorLoginFromStorage;
    const tokenSettings = {
      _id,
      validPoints: values.validPoints,
      access_token
    };
    dispatch(updateDoctorAction(tokenSettings) as any);
    */
  };

  useEffect(() => {

    if (loading === false && called) {
      const { doctor } = called;
      console.log("DOCTOR ", called);
      setDoctor(doctor);
    }
  }, [loading, called],)

  if (loading === true || !doctor) {
    return <div>Loading...</div>
  };

  const TODO= {
    backgroundColor: '#ffff77', //'yellow',
    maxWidth: "320px",
    margin: 'auto',
  };

  return (
    <>
      <div style={{color: "red"}}>{appErr}</div>
      <div style={{color: "red"}}>{serverErr}</div>
      <div 
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          backgroundPositionX: '50%',
          backgroundPositionY: '50%',
          alignItems: 'center',
          width: '100%',
        }}
      >
        <img src={""} alt="Profile Image" />
        <div>
        <Link style={{marginRight: 50}} to={`/admin/doctors/${id}/edit`}>{translate("Edit")}</Link>
        <Link style={{marginRight: 50}} to={`/admin/doctors/${id}/macs`}>{translate("Devices")}</Link>
        <Link to={`/admin/doctors/${id}/patients`}>{translate("Patients")}</Link>
        </div>
        <h2>{translate("Account_Info")}</h2>
        <ul style= {{listStyleType: "none"}}>
          
          <li><b>{translate("Name")}</b>: {doctor.name}</li>
          <li><b>{translate("Email")}</b>: {doctor.email}</li>
        </ul>
        <h2>{translate("Personal_Info")}</h2>
        <ul style= {{listStyleType: "none"}}>
          {/* TODO mac or macs remove from here */}
          {/*
            doctor.devices?.map((item: any, index: number) => (
              <li><b>{translate('Device_MAC') + ' ' + index}</b>: {item.mac}</li>
            ))
          */}
          <li><b>{translate("Tax_ID")}</b>: {doctor.tax_id}</li>
          <li><b>{translate("Practitioner_ID")}</b>: {doctor.practitioner_id}</li>
        </ul>
        <h2>{translate("Clinic_Info")}</h2>
        <ul style= {{listStyleType: "none"}}>
          <li><b>{translate("Clinic_Name")}</b>: {doctor.clinic?.name}</li>
          <li><b>{translate("google_calendar_admin")}</b>: {doctor.google_calendar}</li>
          <li><b>{translate("Phone")}</b>: {doctor.clinic?.phone}</li>
          <li><b>{translate("Street_and_number")}</b>: {doctor.clinic?.address?.street}</li>
          <li><b>{translate("Apartment_suite_unit_building")}</b>: {doctor.clinic?.address?.street2}</li>
          <li><b>{translate("State")}</b>: {config.mexicoStates[doctor.clinic?.address?.state]}</li>
          <li><b>{translate("Postal_Code")}</b>: {doctor.clinic?.address?.zip}</li>
          <li><b>{translate("City")}</b>: {doctor.clinic?.address?.city}</li>
          <li><b>{translate("Country")}</b>: {config.country[doctor.clinic?.address?.country]}</li>
        </ul>
      </div>
    </>
  );
};

export default withLocalize(AdminDoctorShow);
