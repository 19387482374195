import React, {useState, useEffect} from "react";
import { withLocalize } from 'react-localize-redux';
import { useParams, Link } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import { doctorLoginFromStorage } from "../../../redux/slices/doctors/doctorSlices";
import { getOnePatientAction } from "../../../redux/slices/doctors/patientsSlices";
import {RootState, AppDispatch} from '../../../redux/store/store';
import config from "config";

interface IState {
  _id: string;
  active: boolean;
  profile: {
    name: {
      first: string;
      last: string;
    }
    email: string;
    phone: string;
    dob: string;
    address: {
      lines: string[];
      city: string;
      state: string;
      zip: string;
      country: string;
    }
    last_visit: string;
    next_visit: {
      appt_date: string;
      appt_time: number;
      status: string;
    }
    ref_by: string;
  }
  history: {
    height: string;
    weight: number;
    smoker: boolean;
    cigarettes_day: number;
    pregnancies: number;
    liveBirths: number;
    cesareans: number;
    abortions: number;
    sexual_partners: number;
    first_sex: number;
    anal_sex: boolean;
    last_pap_smear: string;
    last_menstruation: string;
    ethnicity: string;
  }
  whats_app: string;
  doctor: object;
}

interface IProps {
  translate: any;
}


interface IAddress {
  lines: string[];
  city: string;
  state: string;
  zip: string;
  country: string;
}

const createAddress= (address: IAddress) => (
  <ul style= {{listStyleType: "none"}}>
    <li>{address.lines[0] ? address.lines[0]: ""}</li>
    <li>{(address.lines[0] ? address.lines[1]+ ", ": "") + (address.zip ? address.zip : "")}</li>
    <li>{
      (address.state ? config.mexicoStates[address.state] + ", " : "") +
      (address.city ? address.city + ", " : "") +
      (address.country ? config.country[address.country] + "." : "")
    }</li>
  </ul>
)

const createEthnicity = (ethnicity: string, translate: any): string => {
  return translate(`${config.ethnecities[parseInt(ethnicity)]}`)
}

const INITIAL_STATE: IState = {
  _id: "",
  profile:{
    name:{
      first:"Ana",
      last:"Cueva"
    },
    email: "",
    phone: "",
    dob: "",
    address: {
      lines:["", ""],
      city:"",
      state:"",
      zip:"",
      country: ""
    },
    last_visit:"",
    next_visit: {
      appt_date: "",
      appt_time: 0,
      status: ""
    },
    ref_by:""
  },
  history: {
    height:"",
    weight:0,
    smoker:false,
    cigarettes_day: 0,
    pregnancies:0,
    liveBirths:0,
    cesareans:0,
    abortions:0,
    sexual_partners:1,
    first_sex:0,
    anal_sex:false,
    last_pap_smear: "",
    last_menstruation:"",
    ethnicity:""
  },
  active:true,
  whats_app:"",
  doctor: {}
}

const DoctorPatientShow: React.FC<IProps> = ({translate}) => {
  const [patient, setPatient] = useState({...INITIAL_STATE})

  const {id} = useParams();

  const {access_token} =JSON.parse(JSON.stringify(doctorLoginFromStorage));

  const dispatch: AppDispatch = useDispatch();

  useEffect(() => {
    const token = {
      access_token,
      patient_id: id
    };

    dispatch(getOnePatientAction(token));
    
  }, [access_token, dispatch, id]); // ?? []

  const store: any = useSelector((state: RootState) => state?.patients);
  const {called, loading, serverErr, appErr} = store;

  useEffect(() => {

    if (loading === false && called) {
      //console.log("CALLED", called);
      const { patient } = called;
      //console.log("PATIENT ", called);
      setPatient(patient);
    }
  }, [loading, called],)

  if (loading === true || !patient) {
    return <div>Loading...</div>
  };

  const TODO= {
    backgroundColor: '#ffff77', //'yellow',
    maxWidth: "320px",
    margin: 'auto',
  };

  return (
    <>
      <div style={{color: "red"}}>{appErr}</div>
      <div style={{color: "red"}}>{serverErr}</div>
    {/*<pre style={{whiteSpace: 'pre-line'}}>{JSON.stringify(patient)}</pre>*/}
      <div 
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          backgroundPositionX: '50%',
          backgroundPositionY: '50%',
          alignItems: 'center',
          width: '100%',
        }}
      >
        <div>
        <Link style={{marginRight: 50}} to={`/doctor/patients/${id}/edit`}>{translate("Edit")}</Link>
        <Link to={`/doctor/patients`}>{translate("Patients")}</Link>
        </div>
        <h2>{translate("Patient_Profile")}</h2>
        <ul style= {{listStyleType: "none"}}>
          <li><b>{translate("Name")}</b> : {patient.profile.name.last}, {patient.profile.name.first} </li>
          <li><b>{translate("Email")}</b> : {patient.profile.email}</li>
          <li><b>{translate("Cell_Phone")}</b> : {patient.profile.phone}</li>
          <li><b>{translate("Birthday")}</b> : {new Date(patient.profile.dob).toLocaleDateString()} </li>
          <li><b>{translate("Address")}</b> : {createAddress(patient.profile.address)} </li>
          <li><b>{translate("Last_Appt")}</b> {new Date(patient.profile.last_visit).toLocaleDateString()} </li>
          <li><b>{translate("Next_Appt")}</b> {new Date(patient.profile.next_visit?.appt_date).toLocaleDateString()} </li>
          <li><b>{translate("Referred_by:")}</b> : {patient.profile.ref_by} </li>
        </ul>
        <h2>{translate("Patient_History")}</h2>
        <ul style= {{listStyleType: "none"}}>
          <li><b>{translate("Height")}</b> : {patient.history.height} </li>
          <li><b>{translate("Weight")}</b> : {patient.history.weight} </li>
          <li><b>{translate("Smoker")}</b> : {patient.history.smoker? translate("Yes"): translate("No")} </li>
          <li><b>{translate("Cigarettes_Day")}</b> : {patient.history.cigarettes_day} </li>
          <li><b>{translate("Pregnancies")}</b> : {patient.history.pregnancies} </li>
          <li><b>{translate("Live_Births")}</b> : {patient.history.liveBirths} </li>
          <li><b>{translate("Cesareans")}</b> : {patient.history.cesareans} </li>
          <li><b>{translate("Abortions")}</b> : {patient.history.abortions} </li>
          <li><b>{translate("Sexual_Partners")}</b> : {patient.history.sexual_partners} </li>
          <li><b>{translate("First_Sexual_Activity")}</b> : {patient.history.first_sex} </li>
          <li><b>{translate("Anal_Sex")}</b> : {patient.history.anal_sex? translate("Yes") : translate("No")} </li>
          <li><b>{translate("Last_Pap_Smear")}</b> : {patient.history.last_pap_smear? new Date(patient.history.last_pap_smear).toLocaleDateString() : ""} </li>
          <li><b>{translate("Last_Menstruation")}</b> : {new Date(patient.history.last_menstruation).toLocaleDateString()} </li>
          <li><b>{translate("Ethnicity")}</b> : {createEthnicity(patient.history.ethnicity, translate)} </li>
          {/* <li><b>{translate("View_Past_Test")}</b> : {patient.profile.ref_by} </li> */}
        </ul>
      </div>
    </>
  );
};

export default withLocalize(DoctorPatientShow);
