//import language from 'languagesp';
import React, {useState, useEffect}  from "react";
import { Box, Typography } from '@mui/material';
import Textfield from '../../FormsUI/Textfield';
import Button from '../../FormsUI/Button';
import { withLocalize } from 'react-localize-redux';
import { Formik, Form } from "formik";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import { doctorLoginFromStorage } from "../../../redux/slices/doctors/doctorSlices";
import { getSettingsAction, updateSettingsAction } from "../../../redux/slices/doctors/settingsSlices";
import {RootState, AppDispatch} from '../../../redux/store/store';

interface IProps {
  translate: any;
  languages: any;
}

interface IState {
  validPoints: number;
}

const INITIAL_FORM_STATE: IState = {
  validPoints: 5,
};

const FORM_VALIDATION = Yup.object().shape({
   validPoints: Yup.number().required("Valid points are required"),
});

const AdminSettings: React.FC<IProps> = ({translate, languages}) => {

  const [validPoints, setValidPoints] = useState(INITIAL_FORM_STATE.validPoints);
  const [settingsId, setSettingsId] = useState(null);
  let language;
  for (let i = 0; i < languages.length; i++) {
    if (languages[i].active) {
      language = languages[i].code;
    }
  }

  const {access_token} =JSON.parse(JSON.stringify(doctorLoginFromStorage));

  const dispatch: AppDispatch = useDispatch();

  useEffect(() => {
    const token = {
      access_token
    };

    dispatch(getSettingsAction(token));
    
  }, [access_token, dispatch, validPoints]);


  /*
  const setAppErrUndefined = () => {
    dispatch(appErrUndefinedAction());
  };
  */

  const handleSubmit= (values: any) => {
      //dispath the action
    //token = doctorLoginFromStorage;
    const tokenSettings = {
      _id: settingsId,
      validPoints: values.validPoints,
      access_token
    };
    dispatch(updateSettingsAction(tokenSettings) as any);
  };

  // redirect

  const store: any = useSelector((state: RootState) => state?.settings);
  const {called, updated, loading, serverErr, appErr} = store;

  useEffect(() => {
    if (loading === false && called) {
      const {_id, validPoints} = called.settings;
      setSettingsId(_id);
      setValidPoints(validPoints);
    }
  }, [loading, called]);

  useEffect(() => {
    if (loading === false && updated) {
      const {validPoints} = updated.settings;
      setValidPoints(validPoints);
    }
  }, [loading, updated]);

  if (loading === true) {
    return <div>Loading...</div>
  }

  return (
  <>
    <Box
      sx= {{
        maxWidth: "320px",
        margin: 'auto',
      }}
    >
      
      <div>{serverErr || appErr}</div>
      <Typography
        sx={{
          margin: "20px 0",
          fontSize: 24
        }}
      >
        {translate("Settings_Panel_Info")}
      </Typography>
      
      <Typography
        sx = {{marginBottom: '20px'}} 
      >
        Regla de puntos actual: {validPoints}
      </Typography>
      <Formik
          initialValues ={{
            ...INITIAL_FORM_STATE, validPoints
          }}
    
          validationSchema ={FORM_VALIDATION}
          onSubmit={values => handleSubmit(values)}
      >
        <Form>
          <Textfield
            name='validPoints'
            label={translate("Points_Rule")}
            placeholder={validPoints.toString()}
          />
          <Button
            sx= {{
              marginTop: '20px',
              backgroundImage: "linear-gradient(to right, #a93d9b 0%, #290d80 100%)"}}
          >
            {translate('Save_Changes')}
          </Button> 
        </Form>
      </Formik>
    </Box>
  </>
  );
};

export default withLocalize(AdminSettings) ;
