import React, { useEffect, useState } from "react";
import { withLocalize } from 'react-localize-redux';
import { useParams, Link } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import { doctorLoginFromStorage } from "../../../redux/slices/doctors/doctorSlices";
import { getOneExamAction } from "../../../redux/slices/doctors/examsSlices";
import {RootState, AppDispatch} from '../../../redux/store/store';
import config from "config";

interface IState {
}
interface IProps {
  translate: any;
}

const INITIAL_STATE = {};

const DoctorExamShow: React.FC<IProps> = ({translate}) => {
  const [exam, setExam] = useState({...INITIAL_STATE})

  const {id} = useParams();

  const {access_token} =JSON.parse(JSON.stringify(doctorLoginFromStorage));

  const dispatch: AppDispatch = useDispatch();

  useEffect(() => {
    const token = {
      access_token,
      exam_id: id
    };

    dispatch(getOneExamAction(token));
    
  }, [access_token, dispatch, id]); // ?? []

  const store: any = useSelector((state: RootState) => state?.exams);
  const {called, loading, serverErr, appErr} = store;

  useEffect(() => {

    if (loading === false && called) {
      console.log("CALLED", called);
      const { exams } = called;
      //console.log("PATIENT ", called);
      setExam(exams.data);
    }
  }, [loading, called],)

  if (loading === true || !exam) {
    return <div>Loading...</div>
  };

  const TODO= {
    backgroundColor: '#ffff77', //'yellow',
    maxWidth: "320px",
    margin: 'auto',
  };

  return (
    <>
      <div style={{color: "red"}}>{appErr}</div>
      <div style={{color: "red"}}>{serverErr}</div>
    {/*<pre style={{whiteSpace: 'pre-line'}}>{JSON.stringify(patient)}</pre>*/}
      <div 
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          backgroundPositionX: '50%',
          backgroundPositionY: '50%',
          alignItems: 'center',
          width: '100%',
        }}
      >
        <div>
        <Link style={{marginRight: 50}} to={`/doctor/exams/${id}/edit`}>{translate("Edit")}</Link>
        <Link to={`/doctor/exams`}>Instapaps</Link>
        </div>
        <pre>{JSON.stringify(exam, null, 2)}</pre>
      </div>
    </>
  );
};

export default withLocalize(DoctorExamShow);
