import React from "react";
import Textfield from '../../FormsUI/Textfield';
import Checkbox from '../../FormsUI/Checkbox';
import DatePicker from '../../FormsUI/DatePicker';
import Button from '../../FormsUI/Button';
import Select from '../../FormsUI/Select';
import { FormLabel, Box, Grid } from '@mui/material';
import { Formik, Form, useFormik } from "formik";
import * as Yup from "yup";
import config from "config";

let dateFormat = config.language.dateTimeFormat;

export interface IPatientFormState {
  profile: {
    name: {
      first: string;
      last: string;
    }
    email: string;
    phone: string;
    dob: string;
    address: {
      lines: string[];
      city: string;
      state: string;
      zip: string;
      country: string;
    }
    last_visit: string;
    next_visit: {
      appt_date: string;
      appt_time: number;
      status: string;
    }
    ref_by: string;
  }
  history: {
    height: string;
    weight: number;
    smoker: boolean;
    cigarettes_day: number;
    pregnancies: number;
    liveBirths: number;
    cesareans: number;
    abortions: number;
    sexual_partners: number;
    first_sex: number;
    anal_sex: boolean;
    last_pap_smear: string;
    last_menstruation: string;
    ethnicity: string;
  }
}

interface IProps {
  translate: any;
  handleSubmit: any;
  initialState: any;
}


export const INITIAL_PATIENT_FORM_STATE: IPatientFormState = {
  profile:{
    name:{
      first:"",
      last:""
    },
    email: "",
    phone: "",
    dob: "",
    address: {
      lines:[],
      city:"",
      state:"",
      zip:"",
      country: ""
    },
    last_visit:"",
    next_visit: {
      appt_date: "",
      appt_time: 0,
      status: ""
    },
    ref_by:""
  },
  history: {
    height:"",
    weight:0,
    smoker:false,
    cigarettes_day: 0,
    pregnancies:0,
    liveBirths:0,
    cesareans:0,
    abortions:0,
    sexual_partners:0,
    first_sex:18,
    anal_sex:false,
    last_pap_smear: "",
    last_menstruation:"",
    ethnicity:""
  },
}

const FORM_VALIDATION = Yup.object().shape({
  profile: Yup.object({
    name: Yup.object({
      first: Yup.string().required("First name is required"),
      last: Yup.string().required("Last name is required"),
    }),
    email: Yup.string().required("Email is required"),
    dob: Yup.string().required("Day of birth is required"),
  }),
});

const AdminPatientForm: React.FC<IProps> = ({translate, handleSubmit, initialState}) => {

  return (
    <>
      <Formik
        enableReinitialize
        initialValues ={{...initialState}}
  
        validationSchema ={FORM_VALIDATION}
        onSubmit={(values: any, {setSubmitting, resetForm}) => {
          setSubmitting(true);
          handleSubmit(values);
          // resetForm();
          setSubmitting(false);
          //console.log("SUBMIT VALS -> ", values);
        }}
      >
      { (formik: any ) => {
      return (
        <Form
          style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              backgroundPositionX: '50%',
              backgroundPositionY: '50%',
              alignItems: 'center',
              width: '100%',
              marginTop: '20px',
          }}
        >
          <FormLabel
            style={{marginBottom: 10, fontSize: 24}}
          >{translate("Patient_Profile")}</FormLabel>
          <Textfield
            style={{marginBottom: 10}}
            name="profile.name.first"
            label={translate("Name")}
            placeholder={translate("Name")}
            variant="filled"
          />
          <Textfield
            style={{marginBottom: 10}}
            name="profile.name.last"
            label={translate("Last_Name")}
            placeholder={translate("Last_Name")}
            variant="filled"
          />
          <Textfield
            style={{marginBottom: 10}}
            name ="profile.email"
            label={translate("Email")}
            placeholder={translate("Email")}
            type="email"
            variant="filled"
          />
          <Textfield
            style={{marginBottom: 10}}
            name ="profile.phone"
            label={translate("Cell_Phone")}
            placeholder={translate("Cell_Phone")}
            variant="filled"
          />
          <DatePicker
            style={{marginBottom: 10}}
            name ="profile.dob"
            format={dateFormat}
            label={translate("Birthday")}
            variant="filled"
          />
          <FormLabel
            style={{marginBottom: 10, fontSize: 16}}
          >{translate("Address")}</FormLabel>
          <Textfield
            style={{marginBottom: 10}}
            name ="profile.address.lines.0"
            label={translate("Street_and_number")}
            placeholder={translate("Street_and_number")}
            variant="filled"
          />
          <Textfield
            style={{marginBottom: 10}}
            name ="profile.address.lines.1"
            label={translate("Apartment_suite_unit_building")}
            placeholder={translate("Apartment_suite_unit_building")}
            variant="filled"
          />
          <Textfield
            style={{marginBottom: 10}}
            name ="profile.address.zip"
            label={translate("Postal_Code")}
            placeholder={translate("Postal_Code")}
            variant="filled"
          />
          <Textfield
            style={{marginBottom: 10}}
            name ="profile.address.city"
            label={translate("City")}
            placeholder={translate("City")}
            variant="filled"
          />
          {/* config.mexicoStates*/}
          <Select
            style={{marginBottom: 10}}
            name ="profile.address.state"
            label={translate("State")}
            placeholder={translate("State")}
            options={config.mexicoStates}
            variant="filled"
          />
          <Select
            style={{marginBottom: 10}}
            name ="profile.address.country"
            label={translate("Country")}
            placeholder={translate("Country")}
            options={config.country}
            variant="filled"
          />
          <Textfield
            style={{marginBottom: 10}}
            name ="profile.ref_by"
            label={translate("How_hear_about_us")}
            placeholder={translate("How_hear_about_us")}
            variant="filled"
          />
          <FormLabel
            style={{marginBottom: 10, fontSize: 24}}
          >{translate("Patient_History")}</FormLabel>
          <Textfield
            style={{marginBottom: 10}}
            name ="history.height"
            label={translate("Height_meters")}
            placeholder={translate("Height_meters")}
            variant="filled"
          />
          <Textfield
            style={{marginBottom: 10}}
            name ="history.weight"
            label={translate("Weight_kg")}
            placeholder={translate("Weight_kg")}
            variant="filled"
          />
          <Checkbox
            style={{marginBottom: 10}}
            name ="history.smoker"
            label={translate("Do_you_smoke")}
            variant="filled"
          />
          <Textfield
            style={{marginBottom: 10}}
            name ="history.cigarettes_day"
            label={translate("cigarettes_day")}
            placeholder={translate("cigarettes_day")}
            variant="filled"
          />
          <Textfield
            style={{marginBottom: 10}}
            name ="history.pregnancies"
            label={translate("Number_Pregnancies")}
            placeholder={translate("Number_Pregnancies")}
            variant="filled"
          />
          <Textfield
            style={{marginBottom: 10}}
            name ="history.liveBirths"
            label={translate("Number_Live_Births")}
            placeholder={translate("Number_Live_Births")}
            variant="filled"
          />
          <Textfield
            style={{marginBottom: 10}}
            name ="history.cesareans"
            label={translate("Number_Cesareans")}
            placeholder={translate("Number_Cesareans")}
            variant="filled"
          />
          <Textfield
            style={{marginBottom: 10}}
            name ="history.abortions"
            label={translate("Number_Abortions")}
            placeholder={translate("Number_Abortions")}
            variant="filled"
          />
          <Textfield
            style={{marginBottom: 10}}
            name ="history.sexual_partners"
            label={translate("Number_Sexual_Partners")}
            placeholder={translate("Number_Sexual_Partners")}
            variant="filled"
          />
          <Textfield
            style={{marginBottom: 10}}
            name ="history.first_sex"
            label={translate("Sexual_Activity_Initiated")}
            placeholder={translate("Sexual_Activity_Initiated")}
            variant="filled"
          />
          <Checkbox
            style={{marginBottom: 10}}
            name ="history.anal_sex"
            label={translate("Anal_Sex")}
            variant="filled"
          />
          <DatePicker
            style={{marginBottom: 10}}
            name ="history.last_pap_smear"
            label={translate("Last_Pap_Smear")}
            variant="filled"
          />
          <DatePicker
            style={{marginBottom: 10}}
            name ="history.last_menstruation"
            label={translate("Last_Menstruation")}
            variant="filled"
          />
          <Select
            style={{marginBottom: 10}}
            name ="history.ethnicity"
            label={translate("Ethnicity")}
            placeholder={translate("Ethnicity")}
            options={config.ethnecities.map((race: any) => translate(race))}
            variant="filled"
          />
          <Button
            type="submit"
            disabled={formik.isSubmitting}
            sx= {{
              backgroundImage: "linear-gradient(to right, #a93d9b 0%, #290d80 100%)"}}
          >
            {translate('Save_Changes')}
          </Button> 
        </Form>
        )}}
      </Formik>
    </>
  );
};

export default AdminPatientForm;
