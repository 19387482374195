let apiUrl;

switch (process.env.NODE_ENV) {
      case 'development':
        apiUrl = process.env.REACT_APP_API_URL_DEV;
        break;
      case 'production':
        apiUrl = process.env.REACT_APP_API_URL;
        break;
      case 'test':
        apiUrl = process.env.REACT_APP_API_URL_TEST;
        break;
      default:
        throw new Error("NODE_ENV no está configurado adecuadamente");
}

export const baseUrl = apiUrl;

