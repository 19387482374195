import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { baseUrl } from "../../../utils/baseURL";

const MACS_INFO = "macsInfo";

export interface MacsState {
  macsInfo: any
  loading: boolean;
  appErr: any;
  serverErr: any;
  called: string;
  created: string;
  updated: string;
  calledList: string;
}

// get macs action
export const getMacsAction: any = createAsyncThunk(
  "macs/getAll/macs",
  async (token, {rejectWithValue, getState, dispatch}) => {

    const {access_token} = token as any;
    const config = {
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Basic ${access_token}`
      },
    };

    try {
      const { data } = await axios.get(
        `${baseUrl}/api/v1/admin/devices/all`,
        config
      );
      return data;
    } catch (error: any) {
      if (!error?.response) {
        throw error;
      }
      return rejectWithValue(error?.response?.data);
    }
  }
);

// get one mac action
export const getOneMacAction: any = createAsyncThunk(
  "macs/getOne/mac",
  async (tokenMacId, {rejectWithValue, getState, dispatch}) => {

    const {access_token, mac_id} = tokenMacId as any;
    const config = {
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Basic ${access_token}`
      },
    };

    try {
      const { data } = await axios.get(
        `${baseUrl}/api/v1/admin/devices/${mac_id}`,
        config
      );
      return data;
    } catch (error: any) {
      if (!error?.response) {
        throw error;
      }
      return rejectWithValue(error?.response?.data);
    }
  }
);

//TODO
// create mac action
export const createMacAction: any = createAsyncThunk(
  "macs/create/mac",
  async (tokenDevice, {rejectWithValue, getState, dispatch}) => {

    const {device, access_token} = tokenDevice as any;
    const body = {
      device
    };
    const config = {
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Basic ${access_token}`
      },
    };

    try {
      const { data } = await axios.post(
        `${baseUrl}/api/v1/admin/devices/new`,
        body,
        config
      );
      return data;
    } catch (error: any) {
      if (!error?.response) {
        throw error;
      }
      return rejectWithValue(error?.response?.data);
    }
  }
);

// update mac action
export const updateMacAction: any = createAsyncThunk(
  "macs/update/mac",
  async (tokenDevice, {rejectWithValue, getState, dispatch}) => {

    const {device, access_token} = tokenDevice as any;
    const body = {
      device
    };
    const config = {
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Basic ${access_token}`
      },
    };

    try {
      const { data } = await axios.put(
        `${baseUrl}/api/v1/admin/devices/edit`,
        body,
        config
      );
      return data;
    } catch (error: any) {
      if (!error?.response) {
        throw error;
      }
      return rejectWithValue(error?.response?.data);
    }
  }
);

// get settings from local storage and place into store
const macsFromStorage: string = localStorage.getItem(MACS_INFO)
  ? JSON.parse(localStorage.getItem(MACS_INFO) || "")
  : null;

const initialState: MacsState = {
  macsInfo: macsFromStorage,
  loading: false,
  appErr: undefined,
  serverErr: undefined,
  called: "",
  updated: "",
  created: "",
  calledList: ""
};

const macsSlices = createSlice({
  name: "macs",
  initialState,
  extraReducers: (builder:any) => {
    // appErrUndefined
    // get All macs
    builder.addCase(getMacsAction.pending, (state: MacsState, action: any) => {
      state.loading = true;
      state.appErr = undefined;
      state.serverErr = undefined;
    });
    builder.addCase(getMacsAction.fulfilled, (state:MacsState, action: any) => {
      state.loading = false;
      state.calledList = action?.payload;
      state.appErr = undefined;
      state.serverErr = undefined;
    });
    builder.addCase(getMacsAction.rejected, (state: MacsState, action: any) => {
      state.loading = false;
      //state.appErr = action?.payload?.message;
      state.appErr = action?.payload?.JsonWebTokenError;
      state.serverErr = action?.error?.message;
    });


    // get One mac
    builder.addCase(getOneMacAction.pending, (state: MacsState, action: any) => {
      state.loading = true;
      state.appErr = undefined;
      state.serverErr = undefined;
    });
    builder.addCase(getOneMacAction.fulfilled, (state: MacsState, action: any) => {
      state.loading = false;
      state.called = action?.payload;
      state.appErr = undefined;
      state.serverErr = undefined;
    });
    builder.addCase(getOneMacAction.rejected, (state: MacsState, action: any) => {
      state.loading = false;
      //state.appErr = action?.payload?.message;
      state.appErr = action?.payload?.JsonWebTokenError;
      state.serverErr = action?.error?.message;
    });

    // create mac
    builder.addCase(createMacAction.pending, (state: MacsState, action: any) => {
      state.loading = true;
      state.appErr = undefined;
      state.serverErr = undefined;
    });
    builder.addCase(createMacAction.fulfilled, (state: MacsState, action: any) => {
      state.loading = false;
      state.created = action?.payload;
      state.appErr = undefined;
      state.serverErr = undefined;
    });
    builder.addCase(createMacAction.rejected, (state: MacsState, action: any) => {
      state.loading = false;
      //state.appErr = action?.payload?.message;
      state.appErr = action?.payload?.JsonWebTokenError;
      state.serverErr = action?.error?.message;
    });

    // update mac
    builder.addCase(updateMacAction.pending, (state: MacsState, action: any) => {
      state.loading = true;
      state.appErr = undefined;
      state.serverErr = undefined;
    });
    builder.addCase(updateMacAction.fulfilled, (state: MacsState, action: any) => {
      state.loading = false;
      state.updated = action?.payload;
      state.appErr = undefined;
      state.serverErr = undefined;
    });
    builder.addCase(updateMacAction.rejected, (state: MacsState, action: any) => {
      state.loading = false;
      //state.appErr = action?.payload?.message;
      state.appErr = action?.payload?.JsonWebTokenError;
      state.serverErr = action?.error?.message;
    });
  },
} as any);

export default macsSlices.reducer;
