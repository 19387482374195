import { useSelector } from "react-redux";
import { Navigate, Outlet } from "react-router-dom";
import { RootState } from '../../../redux/store/store';

const DoctorRoute = () => {

  const store: any = useSelector((state: RootState) => state?.doctors);
  const {doctorAuth, loading, serverErr, appErr} = store;

  if (!doctorAuth || !doctorAuth.doctor) {
    return <Navigate to="/login" replace />;
  }

  return <Outlet/>;
};

export default DoctorRoute;
