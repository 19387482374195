import React, {useState, useEffect} from "react";
import { withLocalize } from 'react-localize-redux';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import { doctorLoginFromStorage } from "../../../redux/slices/doctors/doctorSlices";
import AdminMacForm, {IMacFormState, INITIAL_MAC_FORM_STATE} from "./AdminMacForm";
import { createMacAction } from "../../../redux/slices/doctors/macsSlices";
import { RootState, AppDispatch } from '../../../redux/store/store';

interface IState {
}

interface IProps {
  translate: any;
}

const INITIAL_FORM_STATE: IMacFormState = {
  ...INITIAL_MAC_FORM_STATE
};

const AdminMacNew: React.FC<IProps> = ({translate}) => {
  const [showing, setShowing] = useState(false);
  const {access_token} =JSON.parse(JSON.stringify(doctorLoginFromStorage));

  const dispatch: AppDispatch = useDispatch();

  const store: any = useSelector((state: RootState) => state?.macs);
  const {created, loading, serverErr, appErr} = store;
  const navigate = useNavigate();

  const handleSubmit= (values: any) => {
      //dispath the action
    console.log("DEVICE VALUES", values);
    const tokenDevice = {
      device: values,
      access_token
    };
    setShowing(true);
    dispatch(createMacAction(tokenDevice) as any);
  };

  useEffect(() => {

    if (loading === false && created && showing) {
      //console.log("CREATED MAC ", created);

      navigate(`/admin/macs/${created._id}`); // TODO
    }
  }, [loading, created, navigate, showing]);

  if (loading === true) {
    return <div>Loading...</div>
  };

  const TODO= {
    backgroundColor: '#ffffff', //'yellow',
    maxWidth: "340px",
    margin: 'auto',
  };

  return (
    <div style = {TODO}>
      <div style={{color: "red"}}>{appErr}</div>
      <div style={{color: "red"}}>{serverErr}</div>
      <AdminMacForm
        initialState = {{...INITIAL_FORM_STATE}}
        translate= {translate}
        handleSubmit = {handleSubmit}
      />
    </div>
  );
};

export default withLocalize(AdminMacNew);
