import React from "react";
import Textfield from '../../FormsUI/Textfield';
import Checkbox from '../../FormsUI/Checkbox';
import Button from '../../FormsUI/Button';
import { FormLabel } from '@mui/material';
import { Formik, Form } from "formik";
import * as Yup from "yup";

export interface IMacFormState {
  active: boolean;
  mac: string;
}

interface IProps {
  translate: any;
  handleSubmit: any;
  initialState: any;
}

export const INITIAL_MAC_FORM_STATE: IMacFormState = {
  active: true,
  mac: "",
}

const FORM_VALIDATION = Yup.object().shape({
   mac: Yup.string().required("Mac is required"),
});

const AdminMacForm: React.FC<IProps> = ({translate, handleSubmit, initialState}) => {

  return (
    <>
      <Formik
        enableReinitialize
        initialValues ={{
          ...initialState
        }}
  
        validationSchema ={FORM_VALIDATION}
        onSubmit={(values: any,{setSubmitting, resetForm}) => {
          setSubmitting(true);
          handleSubmit(values)
          // resetForm();
          setSubmitting(false);
        }}
      >
        <Form
          style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              backgroundPositionX: '50%',
              backgroundPositionY: '50%',
              alignItems: 'center',
              width: '100%',
              marginTop: '20px',
          }}
        >
          <FormLabel
            style={{marginBottom: 10, fontSize: 24}}
          >{translate("Device_Info")}</FormLabel>
          <Textfield
            style={{marginBottom: 10}}
            name ="mac"
            label={translate("Device_MAC")}
            placeholder={translate("Device_MAC")}
            variant="filled"
          />
          <Checkbox
            style={{marginBottom: 10}}
            name ="active"
            label={translate("Device_Active")}
            variant="filled"
          />

          <Button
            type="submit"
            sx= {{
              backgroundImage: "linear-gradient(to right, #a93d9b 0%, #290d80 100%)"}}
          >
            {translate('Save_Changes')}
          </Button> 
        </Form>
      </Formik>
      {/*
          {
            doctor.devices?.map((item, index)=> (
              <li><b>{translate('Device_MAC') + ' ' + index}</b>: {}</li>
            ))
          }
      */}
    </>
  );
};

export default AdminMacForm;
