import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import {setActiveLanguage, addTranslationForLanguage, initialize, LocalizeProvider } from 'react-localize-redux';
import './index.css';
import App from './components/App';
import store from './redux/store/store';
import reportWebVitals from './reportWebVitals';
import { renderToStaticMarkup } from 'react-dom/server';

const languages=['en','sp'];
let languagesp =  require('languagesp').default;
let config =  require('config');
//store.dispatch(initialize(local as any, { defaultLanguage: process.env.APP_LANG || config.language.key }));
    store.dispatch(initialize({
      languages: languages,
      options: {
        renderToStaticMarkup,
        onMissingTranslation: () => 'Error en traducción'
      }
    }));
    store.dispatch(addTranslationForLanguage(languagesp.en, 'en'));
    store.dispatch(addTranslationForLanguage(languagesp.sp, 'sp'));
    //props.setActiveLanguage('sp');
    store.dispatch(setActiveLanguage(process.env.REACT_APP_API_SP || config.language.key));

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <Provider store={store}>
    <LocalizeProvider store={store}>
      <App />
    </LocalizeProvider>
  </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
