import React, {useState, useEffect} from "react";
import { withLocalize } from 'react-localize-redux';
import { useParams, Link } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import { doctorLoginFromStorage } from "../../../redux/slices/doctors/doctorSlices";
import { getOneMacAction } from "../../../redux/slices/doctors/macsSlices";
import {RootState, AppDispatch} from '../../../redux/store/store';

interface IState {
  _id: string;
  active: boolean;
  mac: string;
}

interface IProps {
  translate: any;
}

const INITIAL_STATE: IState = {
  _id: "",
  active:true,
  mac:""
}

const AdminMacShow: React.FC<IProps> = ({translate}) => {
  const [mac, setMac] = useState({...INITIAL_STATE})

  const {id} = useParams();

  const {access_token} =JSON.parse(JSON.stringify(doctorLoginFromStorage));

  const dispatch: AppDispatch = useDispatch();

  useEffect(() => {
    const token = {
      access_token,
      mac_id: id
    };

    dispatch(getOneMacAction(token));
    
  }, [access_token, dispatch, id]); // ?? []

  const store: any = useSelector((state: RootState) => state?.macs);
  const {called, loading, serverErr, appErr} = store;

  useEffect(() => {

    if (loading === false && called) {
      //console.log("CALLED", called);
      const { mac } = called;
      //console.log("MAC ", mac);
      setMac(mac);
    }
  }, [loading, called],)

  if (loading === true || !mac) {
    return <div>Loading...</div>
  };

  const TODO= {
    backgroundColor: '#ffff77', //'yellow',
    maxWidth: "320px",
    margin: 'auto',
  };

  return (
    <>
      <div style={{color: "red"}}>{appErr}</div>
      <div style={{color: "red"}}>{serverErr}</div>
    {/*<pre style={{whiteSpace: 'pre-line'}}>{JSON.stringify(patient)}</pre>*/}
      <div 
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          backgroundPositionX: '50%',
          backgroundPositionY: '50%',
          alignItems: 'center',
          width: '100%',
        }}
      >
        <div>
        <Link style={{marginRight: 50}} to={`/admin/macs/${id}/edit`}>{translate("Edit")}</Link>
        <Link to={`/admin/macs`}>{translate("Devices")}</Link>
        </div>
        <h2>{translate("Device_MAC")}</h2>
        <ul style= {{listStyleType: "none"}}>
          <li><b>{translate("Device_MAC")}</b> : {mac.mac}</li>
          <li><b>{translate("Device_Status")}</b> : {mac.active? translate("Device_Active"): translate("Device_Inactive")} </li>
        </ul>
      </div>
    </>
  );
};

export default withLocalize(AdminMacShow);
