import React from 'react'
import { TextField } from '@mui/material';
import { useField, useFormikContext, Field } from 'formik';
import Jsonreader from './Jsonreader';

const JsonfieldWrapper = ({
  name,
  ...otherProps
}: any) => {

  const {setFieldValue} = useFormikContext();
  const [field, meta] = useField(name);

  const handleChange = (value: any) => {
    setFieldValue(name, value);
  };

  const configJsonfield = {
    ...field,
    ...otherProps,
    fullWidth: true,
    variant: 'outlined',
    onChange: handleChange,
  };

  if (meta && meta.touched && meta.error) {
    configJsonfield.error = true;
    configJsonfield.helperText = meta.error;

  }

  return (
    <Field
      component ={Jsonreader}
      {...configJsonfield}
    />
  );

};

export default JsonfieldWrapper;
