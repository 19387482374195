import {useState, useEffect} from 'react';
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";

import AdminNavbar from "./AdminNavbar";
import DoctorNavbar from "./DoctorNavbar";
import PublicNavbar from "./PublicNavbar";
import {RootState} from '../../redux/store/store';

interface IState {
}
interface IProps {
}

const ADMIN_DASHBOARD = "adminDashboard";
const DOCTOR_DASHBOARD = "doctorDashboard";
const NO_DASHBOARD = "noDashboard";

const Navbar: React.FC<IProps> = () => {
  const store: any = useSelector((state: RootState) => state?.doctors);
  const {doctorAuth} = store;
  const [dashboard, setDashboard] = useState(NO_DASHBOARD);
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() =>  {
      if (location.pathname.startsWith("/admin")) {
        if (doctorAuth && doctorAuth.doctor.admin) {
          setDashboard(ADMIN_DASHBOARD);
        } else {
          navigate("/doctor");
        }
      } else if (location.pathname.startsWith("/doctor")) {
        if (doctorAuth) {
          setDashboard(DOCTOR_DASHBOARD)
        } else {
          navigate("/");
        }
    } else {
      setDashboard(NO_DASHBOARD)
    }

  }, [location, doctorAuth, navigate]);

  return (
    <>
      {dashboard === ADMIN_DASHBOARD ? (
        <AdminNavbar />
      ) : dashboard === DOCTOR_DASHBOARD ? (
        <DoctorNavbar />
      ) : <PublicNavbar />}
    </>
  );
};

export default Navbar;
