import React from "react";

interface IState {
}
interface IProps {
}

const Counter: React.FC<IProps> = () => {
  return (
    <>
      <h2>AdminDoctorPatients</h2>
    </>
  );
};

export default Counter;
