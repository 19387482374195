import React, {useState, useEffect} from "react";
import { withLocalize } from 'react-localize-redux';
import { useParams, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import { doctorLoginFromStorage } from "../../../redux/slices/doctors/doctorSlices";
import AdminMacForm, {IMacFormState, INITIAL_MAC_FORM_STATE} from "./AdminMacForm";
import { getOneMacAction, updateMacAction } from "../../../redux/slices/doctors/macsSlices";
import { RootState, AppDispatch } from '../../../redux/store/store';

interface IState {
}

interface IProps {
  translate: any;
}

const INITIAL_FORM_STATE: IMacFormState = {
  ...INITIAL_MAC_FORM_STATE
};

const AdminMacNew: React.FC<IProps> = ({translate}) => {
  const [mac, setMac] = useState({...INITIAL_FORM_STATE});
  const [showing, setShowing] = useState(false);
  const {access_token} =JSON.parse(JSON.stringify(doctorLoginFromStorage));

  const dispatch: AppDispatch = useDispatch();


  const store: any = useSelector((state: RootState) => state?.macs);
  const {called, updated, loading, serverErr, appErr} = store;
  const navigate = useNavigate();
  const {id} = useParams();

  const handleSubmit= (values: any) => {
      //dispath the action
    console.log("DEVICE VALUES", values);
    const tokenDevice = {
      device: values,
      access_token
    };
    setShowing(true);
    dispatch(updateMacAction(tokenDevice) as any);
  };

  useEffect(() => {
    const token = {
      access_token,
      mac_id: id
    };

    dispatch(getOneMacAction(token));
    
  }, [access_token, dispatch, id]); // ?? []

  useEffect(() => {

  if (loading === false && updated && showing) {
      //console.log("CREATED MAC ", created);

      navigate(`/admin/macs/${id}`); // TODO
    }
  }, [loading, updated, navigate, showing, id]);

  useEffect(() => {

    if (loading === false && called) {

      const { mac } = called;
      const {__v, ...other} = mac;

      //console.log( "CALLED MAC", other);

      setMac(other);
    }
  }, [loading, called],)

  if (loading === true) {
    return <div>Loading...</div>
  };

  const TODO= {
    backgroundColor: '#ffffff', //'yellow',
    maxWidth: "340px",
    margin: 'auto',
  };

  return (
    <div style = {TODO}>
      <div style={{color: "red"}}>{appErr}</div>
      <div style={{color: "red"}}>{serverErr}</div>
      <AdminMacForm
        initialState = {{...mac}}
        translate= {translate}
        handleSubmit = {handleSubmit}
      />
    </div>
  );
};

export default withLocalize(AdminMacNew);
