import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { baseUrl } from "../../../utils/baseURL";

const DOCTOR_INFO = "doctorInfo";

export interface DoctorState {
  doctorAuth: any;
  loading: boolean;
  appErr: any;
  serverErr: any;
  registered: string;
}


export const appErrUndefinedAction: any = createAsyncThunk(
  "doctors/appErrUndefined",
  async (doctorData, {rejectWithValue, getState, dispatch}) => {

    try {
    } catch (error:any) {
      if (!error?.response) {
        throw error;
      }
      return rejectWithValue(error?.response?.data);
    }
  }
);

// create action
export const createDoctorAction: any = createAsyncThunk(
  "doctors/create",
  async (doctor, {rejectWithValue, getState, dispatch}) => {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    try {
      const { data } = await axios.post(
        `${baseUrl}/api/v1/doctors`,
        doctor,
        config
      );
      return data;
    } catch (error: any) {
      if (!error?.response) {
        throw error;
      }
      return rejectWithValue(error?.response?.data);
    }
  }
);

// forgot pass action
export const forgotPasswordAction: any = createAsyncThunk(
  "doctors/forgot/password",
  async (email, {rejectWithValue, getState, dispatch}) => {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    try {
      const { data } = await axios.post(
        `${baseUrl}/api/v1/doctors/reset-password`,
        email,
        config
      );
      return data;
    } catch (error: any) {
      if (!error?.response) {
        throw error;
      }
      return rejectWithValue(error?.response?.data);
    }
  }
);

// change pass action
export const changePasswordAction: any = createAsyncThunk(
  "doctors/change/password",
  async (tokenPass, {rejectWithValue, getState, dispatch}) => {

    const {password, access_token} = tokenPass as any;
    const body = {
      doctor: {
        password
      }
    };
    const config = {
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Basic ${access_token}`
      },
    };

    try {
      const { data } = await axios.put(
        `${baseUrl}/api/v1/doctors/password`,
        body,
        config
      );
      return data;
    } catch (error: any) {
      if (!error?.response) {
        throw error;
      }
      return rejectWithValue(error?.response?.data);
    }
  }
);

//<{email: string, password: string}>
// login action
export const loginDoctorAction = createAsyncThunk<
    DoctorState,
  {}>(
  "doctors/login",
  async (doctorData, {rejectWithValue, getState, dispatch}) => {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    try {
      console.log(doctorData);
      const { data } = await axios.post(
        `${baseUrl}/api/v1/doctors/login`,
        doctorData,
        config
      );
      localStorage.setItem(DOCTOR_INFO, JSON.stringify(data));
      return data;
    } catch (error:any) {
      if (!error?.response) {
        throw error;
      }
      return rejectWithValue(error?.response?.data);
    }
  }
);

// Logout action
export const logoutDoctorAction = createAsyncThunk(
  "doctors/logout",
  async (doctorData, {rejectWithValue, getState, dispatch}) => {

    try {
      localStorage.removeItem(DOCTOR_INFO);
    } catch (error:any) {
      if (!error?.response) {
        throw error;
      }
      return rejectWithValue(error?.response?.data);
    }
  }
);

// get doctor from local storage and place into store
export const doctorLoginFromStorage: string = localStorage.getItem(DOCTOR_INFO)
  ? JSON.parse(localStorage.getItem(DOCTOR_INFO) || "")
  : null;

const initialState: DoctorState = {
  doctorAuth: doctorLoginFromStorage,
  loading: false,
  appErr: undefined,
  serverErr: undefined,
  registered: ""
};

// slices
const doctorSlices = createSlice({
  name: "doctors",
  initialState,
  extraReducers: (builder:any) => {
    // appErrUndefined

    builder.addCase(appErrUndefinedAction.fulfilled, (state: DoctorState, action: any) => {
      state.loading = false;
      state.appErr = undefined;
      state.serverErr = undefined;
    });

    // create
    builder.addCase(createDoctorAction.pending, (state: DoctorState, action: any) => {
      state.loading = true;
      state.appErr = undefined;
      state.serverErr = undefined;
    });
    builder.addCase(createDoctorAction.fulfilled, (state: DoctorState, action: any) => {
      state.loading = false;
      state.registered = action?.payload;
      state.appErr = undefined;
      state.serverErr = undefined;
    });
    builder.addCase(createDoctorAction.rejected, (state: DoctorState, action: any) => {
      state.loading = false;
      state.appErr = action?.payload?.message;
      state.serverErr = action?.error?.message;
    });

    // forgot password
    builder.addCase(forgotPasswordAction.pending, (state: DoctorState, action: any) => {
      state.loading = true;
      state.appErr = undefined;
      state.serverErr = undefined;
    });
    builder.addCase(forgotPasswordAction.fulfilled, (state: DoctorState, action: any) => {
      state.loading = false;
      state.registered = action?.payload;
      state.appErr = undefined;
      state.serverErr = undefined;
    });
    builder.addCase(forgotPasswordAction.rejected, (state: DoctorState, action: any) => {
      state.loading = false;
      state.appErr = action?.payload?.message;
      state.serverErr = action?.error?.message;
    });

    // change password
    builder.addCase(changePasswordAction.pending, (state: DoctorState, action: any) => {
      state.loading = true;
      state.appErr = undefined;
      state.serverErr = undefined;
    });
    builder.addCase(changePasswordAction.fulfilled, (state: DoctorState, action: any) => {
      state.loading = false;
      state.registered = action?.payload;
      state.appErr = undefined;
      state.serverErr = undefined;
    });
    builder.addCase(changePasswordAction.rejected, (state: DoctorState, action: any) => {
      state.loading = false;
      //state.appErr = action?.payload?.message;
      state.appErr = action?.payload?.JsonWebTokenError;
      state.serverErr = action?.error?.message;
    });

    // login
    builder.addCase(loginDoctorAction.pending, (state: DoctorState, action: any) => {
      state.loading = true;
      state.appErr = undefined;
      state.serverErr = undefined;
    });
    builder.addCase(loginDoctorAction.fulfilled, (state: DoctorState, action: any) => {
      state.doctorAuth = action?.payload;
      state.loading = false;
      state.appErr = undefined;
      state.serverErr = undefined;
    });
    builder.addCase(loginDoctorAction.rejected, (state: DoctorState, action: any) => {
      state.loading = false;
      state.appErr = action?.payload?.message;
      state.serverErr = action?.error?.message;
    });

    // logout
    builder.addCase(logoutDoctorAction.pending, (state: DoctorState, action: any) => {
      state.loading = false;
      //state.appErr = undefined;
      //state.serverErr = undefined;
    });
    builder.addCase(logoutDoctorAction.fulfilled, (state: DoctorState, action: any) => {
      state.doctorAuth = undefined;
      state.loading = false;
      state.appErr = undefined;
      state.serverErr = undefined;
    });
    builder.addCase(logoutDoctorAction.rejected, (state: DoctorState, action: any) => {
      state.loading = false;
      state.appErr = action?.payload?.message;
      state.serverErr = action?.error?.message;
    });
  },
} as any);

export default doctorSlices.reducer;
