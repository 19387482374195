import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { baseUrl } from "../../../utils/baseURL";

const EXAMS_INFO = "examsInfo";

export interface ExamsState {
  examsInfo: any
  loading: boolean;
  appErr: any;
  serverErr: any;
  called: string;
  created: string;
  updated: string;
  calledList: string;
}

// get exams action
export const getExamsAction: any = createAsyncThunk(
  "exams/getAll/exams",
  async (token, {rejectWithValue, getState, dispatch}) => {

    const {access_token} = token as any;
    const config = {
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Basic ${access_token}`
      },
    };

    try {
      const { data } = await axios.get(
        `${baseUrl}/api/v1/exams/`,
        config
      );
      return data;
    } catch (error: any) {
      if (!error?.response) {
        throw error;
      }
      return rejectWithValue(error?.response?.data);
    }
  }
);

// get one exam action
export const getOneExamAction: any = createAsyncThunk(
  "exams/getOne/exam",
  async (tokenExamId, {rejectWithValue, getState, dispatch}) => {

    const {access_token, exam_id} = tokenExamId as any;
    const config = {
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Basic ${access_token}`
      },
    };

    try {
      const { data } = await axios.get(
        `${baseUrl}/api/v1/exams/${exam_id}`,
        config
      );
      return data;
    } catch (error: any) {
      if (!error?.response) {
        throw error;
      }
      return rejectWithValue(error?.response?.data);
    }
  }
);

//TODO
// create exam action
export const createExamAction: any = createAsyncThunk(
  "exams/create/exam",
  async (tokenExamMessage, {rejectWithValue, getState, dispatch}) => {

    const {exam, message_id, access_token} = tokenExamMessage as any;
    const body = {
      exam
    };
    const config = {
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Basic ${access_token}`
      },
    };

    try {
      const { data } = await axios.post(
        `${baseUrl}/api/v1/exams/upload/${message_id}`,
        body,
        config
      );
      return data;
    } catch (error: any) {
      if (!error?.response) {
        throw error;
      }
      return rejectWithValue(error?.response?.data);
    }
  }
);

// update exam action
export const updateExamAction: any = createAsyncThunk(
  "exams/update/exam",
  async (tokenDevice, {rejectWithValue, getState, dispatch}) => {

    const {exam, access_token} = tokenDevice as any;
    const body = [
      exam,
    ];
    console.log("BODY ", exam);
    const config = {
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Basic ${access_token}`
      },
    };

    try {
      const { data } = await axios.put(
        `${baseUrl}/api/v1/exams/patients`,
        body,
        config
      );
      return data;
    } catch (error: any) {
      if (!error?.response) {
        throw error;
      }
      return rejectWithValue(error?.response?.data);
    }
  }
);

// get settings from local storage and place into store
const examsFromStorage: string = localStorage.getItem(EXAMS_INFO)
  ? JSON.parse(localStorage.getItem(EXAMS_INFO) || "")
  : null;

const initialState: ExamsState = {
  examsInfo: examsFromStorage,
  loading: false,
  appErr: undefined,
  serverErr: undefined,
  called: "",
  updated: "",
  created: "",
  calledList: ""
};

const examsSlices = createSlice({
  name: "exams",
  initialState,
  extraReducers: (builder:any) => {
    // appErrUndefined
    // get All exams
    builder.addCase(getExamsAction.pending, (state: ExamsState, action: any) => {
      state.loading = true;
      state.appErr = undefined;
      state.serverErr = undefined;
    });
    builder.addCase(getExamsAction.fulfilled, (state:ExamsState, action: any) => {
      state.loading = false;
      state.calledList = action?.payload;
      state.appErr = undefined;
      state.serverErr = undefined;
    });
    builder.addCase(getExamsAction.rejected, (state: ExamsState, action: any) => {
      state.loading = false;
      //state.appErr = action?.payload?.message;
      state.appErr = action?.payload?.JsonWebTokenError;
      state.serverErr = action?.error?.message;
    });


    // get One exam
    builder.addCase(getOneExamAction.pending, (state: ExamsState, action: any) => {
      state.loading = true;
      state.appErr = undefined;
      state.serverErr = undefined;
    });
    builder.addCase(getOneExamAction.fulfilled, (state: ExamsState, action: any) => {
      state.loading = false;
      state.called = action?.payload;
      state.appErr = undefined;
      state.serverErr = undefined;
    });
    builder.addCase(getOneExamAction.rejected, (state: ExamsState, action: any) => {
      state.loading = false;
      //state.appErr = action?.payload?.message;
      state.appErr = action?.payload?.JsonWebTokenError;
      state.serverErr = action?.error?.message;
    });

    // create exam
    builder.addCase(createExamAction.pending, (state: ExamsState, action: any) => {
      state.loading = true;
      state.appErr = undefined;
      state.serverErr = undefined;
    });
    builder.addCase(createExamAction.fulfilled, (state: ExamsState, action: any) => {
      state.loading = false;
      state.created = action?.payload;
      state.appErr = undefined;
      state.serverErr = undefined;
    });
    builder.addCase(createExamAction.rejected, (state: ExamsState, action: any) => {
      state.loading = false;
      //state.appErr = action?.payload?.message;
      state.appErr = action?.payload?.JsonWebTokenError;
      state.serverErr = action?.error?.message;
    });

    // update exam
    builder.addCase(updateExamAction.pending, (state: ExamsState, action: any) => {
      state.loading = true;
      state.appErr = undefined;
      state.serverErr = undefined;
    });
    builder.addCase(updateExamAction.fulfilled, (state: ExamsState, action: any) => {
      state.loading = false;
      state.updated = action?.payload;
      state.appErr = undefined;
      state.serverErr = undefined;
    });
    builder.addCase(updateExamAction.rejected, (state: ExamsState, action: any) => {
      state.loading = false;
      //state.appErr = action?.payload?.message;
      state.appErr = action?.payload?.JsonWebTokenError;
      state.serverErr = action?.error?.message;
    });
  },
} as any);

export default examsSlices.reducer;
