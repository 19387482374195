import React, {useState, useEffect} from "react";
import Textfield from '../../FormsUI/Textfield';
import Button from '../../FormsUI/Button';
import Select from '../../FormsUI/Select';
import { FormLabel, Box, Grid } from '@mui/material';
import { Formik, Form } from "formik";
import * as Yup from "yup";
import config from "config";

export interface IDoctorFormState {
  name: string;
  email: string;
  google_calendar: string;
  devices: string[];
  tax_id: string;
  practitioner_id: string;
  clinic: {
    name: string;
    phone: string;
    address: {
      street: string;
      street2: string;
      city: string;
      state: string;
      zip: string;
      country: string;
    }
  }
  password: string;
  confirm_password: string;
}

interface IProps {
  translate: any;
  handleSubmit: any;
  initialState: any;
}

export const INITIAL_DOCTOR_FORM_STATE: IDoctorFormState = {
  name: "",
  email: "",
  google_calendar: "",
  devices: [],
  tax_id: "",
  practitioner_id: "",
  clinic: {
    name: "",
    phone: "",
    address: {
      street: "",
      street2: "",
      city: "",
      state: "",
      zip: "",
      country: "",
    }
  },
  password: "",
  confirm_password: "",
}

const FORM_VALIDATION = Yup.object().shape({
   name: Yup.string().required("Name is required"),
   email: Yup.string().required("Email is required"),
   password: Yup.string().required("Password is required"),
   confirm_password: Yup.string().oneOf([Yup.ref("password"), null], "Passwords are not equal").required(),
});

const AdminDoctorForm: React.FC<IProps> = ({translate, handleSubmit, initialState}) => {

  return (
    <>
      <Formik
        enableReinitialize
        initialValues ={{
          ...initialState
        }}
  
        validationSchema ={FORM_VALIDATION}
        onSubmit={(values: any,{setSubmitting, resetForm}) => {
          setSubmitting(true);
          handleSubmit(values)
          // resetForm();
          setSubmitting(false);
        }}
      >
        <Form
          style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              backgroundPositionX: '50%',
              backgroundPositionY: '50%',
              alignItems: 'center',
              width: '100%',
              marginTop: '20px',
          }}
        >
          <FormLabel
            style={{marginBottom: 10, fontSize: 24}}
          >{translate("Account_Info")}</FormLabel>
          <Textfield
            style={{marginBottom: 10}}
            name="name"
            label={translate("Name")}
            placeholder={translate("Name")}
            variant="filled"
          />
          <Textfield
            style={{marginBottom: 10}}
            name ="email"
            label={translate("Email")}
            placeholder={translate("Email")}
            type="email"
            variant="filled"
          />
          <Textfield
            style={{marginBottom: 10}}
            name ="password"
            label={translate("Password")}
            placeholder={translate("Password")}
            type="password"
            variant="filled"
          />
          <Textfield
            style={{marginBottom: 10}}
            name ="confirm_password"
            label={translate("Confirm_Password")}
            placeholder={translate("Confirm_Password")}
            type="password"
            variant="filled"
          />
          <FormLabel
            style={{marginBottom: 10, fontSize: 24}}
          >{translate("Personal_Info")}</FormLabel>
          <Textfield
            style={{marginBottom: 10}}
            name ="tax_id"
            label={translate("Tax_ID")}
            placeholder={translate("Tax_ID")}
            variant="filled"
          />
          <Textfield
            style={{marginBottom: 10}}
            name ="practitioner_id"
            label={translate("Practitioner_ID")}
            placeholder={translate("Practitioner_ID")}
            variant="filled"
          />

          <FormLabel
            style={{marginBottom: 10, fontSize: 24}}
          >{translate("Clinic_Info")}</FormLabel>
          <Textfield
            style={{marginBottom: 10}}
            name ="clinic.name"
            label={translate("Clinic_Name")}
            placeholder={translate("Clinic_Name")}
            variant="filled"
          />
          <Textfield
            style={{marginBottom: 10}}
            name ="google_calendar"
            label={translate("google_calendar_admin")}
            placeholder={translate("google_calendar_admin")}
            variant="filled"
          />
          <Textfield
            style={{marginBottom: 10}}
            name ="clinic.phone"
            label={translate("Phone")}
            placeholder={translate("Phone")}
            variant="filled"
          />
          <Textfield
            style={{marginBottom: 10}}
            name ="clinic.address.street"
            label={translate("Street_and_number")}
            placeholder={translate("Street_and_number")}
            variant="filled"
          />
          <Textfield
            style={{marginBottom: 10}}
            name ="clinic.address.street2"
            label={translate("Apartment_suite_unit_building")}
            placeholder={translate("Apartment_suite_unit_building")}
            variant="filled"
          />
          {/* config.mexicoStates*/}
          <Select
            style={{marginBottom: 10}}
            name ="clinic.address.state"
            label={translate("State")}
            placeholder={translate("State")}
            options={config.mexicoStates}
            variant="filled"
          />
          <Textfield
            style={{marginBottom: 10}}
            name ="clinic.address.zip"
            label={translate("Postal_Code")}
            placeholder={translate("Postal_Code")}
            variant="filled"
          />
          <Textfield
            style={{marginBottom: 10}}
            name ="clinic.address.city"
            label={translate("City")}
            placeholder={translate("City")}
            variant="filled"
          />
          <Select
            style={{marginBottom: 10}}
            name ="clinic.address.country"
            label={translate("Country")}
            placeholder={translate("Country")}
            options={config.country}
            variant="filled"
          />
          <Button
            type="submit"
            sx= {{
              backgroundImage: "linear-gradient(to right, #a93d9b 0%, #290d80 100%)"}}
          >
            {translate('Save_Changes')}
          </Button> 
        </Form>
      </Formik>
      {/*
          {
            doctor.devices?.map((item, index)=> (
              <li><b>{translate('Device_MAC') + ' ' + index}</b>: {}</li>
            ))
          }
      */}
    </>
  );
};

export default AdminDoctorForm;
