import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { baseUrl } from "../../../utils/baseURL";

const PATIENTS_INFO = "patientsInfo";

export interface PatientsState {
  patientsInfo: any
  loading: boolean;
  appErr: any;
  serverErr: any;
  called: string;
  created: string;
  updated: string;
  calledList: string;
}

// update settings action
export const getPatientsAction: any = createAsyncThunk(
  "patients/getAll/patients",
  async (token, {rejectWithValue, getState, dispatch}) => {

    const {access_token} = token as any;
    const config = {
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Basic ${access_token}`
      },
    };

    try {
      const { data } = await axios.get(
        `${baseUrl}/api/v1/patients`,
        config
      );
      return data;
    } catch (error: any) {
      if (!error?.response) {
        throw error;
      }
      return rejectWithValue(error?.response?.data);
    }
  }
);

// get one patient action
export const getOnePatientAction: any = createAsyncThunk(
  "patients/getOne/patient",
  async (tokenPatientId, {rejectWithValue, getState, dispatch}) => {

    const {access_token, patient_id} = tokenPatientId as any;
    const config = {
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Basic ${access_token}`
      },
    };

    try {
      const { data } = await axios.get(
        `${baseUrl}/api/v1/patients/${patient_id}`,
        config
      );
      return data;
    } catch (error: any) {
      if (!error?.response) {
        throw error;
      }
      return rejectWithValue(error?.response?.data);
    }
  }
);

//TODO
// create user action
export const createPatientAction: any = createAsyncThunk(
  "patients/create/patient",
  async (tokenPatient, {rejectWithValue, getState, dispatch}) => {

    const {patient, access_token} = tokenPatient as any;
    const body = {
      ...patient
    };
    const config = {
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Basic ${access_token}`
      },
    };

    try {
      const { data } = await axios.post(
        `${baseUrl}/api/v1/patients`,
        body,
        config
      );
      return data;
    } catch (error: any) {
      if (!error?.response) {
        throw error;
      }
      return rejectWithValue(error?.response?.data);
    }
  }
);

// update patient action
export const updatePatientAction: any = createAsyncThunk(
  "patients/update/patient",
  async (tokenPatient, {rejectWithValue, getState, dispatch}) => {

    const {patient, access_token} = tokenPatient as any;
    console.log("PATIENT", patient);
    const body = {
      patient: {...patient}
    };
    const config = {
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Basic ${access_token}`
      },
    };

    try {
      const { data } = await axios.put(
        `${baseUrl}/api/v1/patients`,
        body,
        config
      );
      return data;
    } catch (error: any) {
      if (!error?.response) {
        throw error;
      }
      return rejectWithValue(error?.response?.data);
    }
  }
);

// get settings from local storage and place into store
const settingsFromStorage: string = localStorage.getItem(PATIENTS_INFO)
  ? JSON.parse(localStorage.getItem(PATIENTS_INFO) || "")
  : null;

const initialState: PatientsState = {
  patientsInfo: settingsFromStorage,
  loading: false,
  appErr: undefined,
  serverErr: undefined,
  called: "",
  updated: "",
  created: "",
  calledList: ""
};

const patientSlices = createSlice({
  name: "patients",
  initialState,
  extraReducers: (builder:any) => {
    // appErrUndefined
    // get All patients
    builder.addCase(getPatientsAction.pending, (state: PatientsState, action: any) => {
      state.loading = true;
      state.appErr = undefined;
      state.serverErr = undefined;
    });
    builder.addCase(getPatientsAction.fulfilled, (state:PatientsState, action: any) => {
      state.loading = false;
      state.calledList = action?.payload;
      state.appErr = undefined;
      state.serverErr = undefined;
    });
    builder.addCase(getPatientsAction.rejected, (state: PatientsState, action: any) => {
      state.loading = false;
      //state.appErr = action?.payload?.message;
      state.appErr = action?.payload?.JsonWebTokenError;
      state.serverErr = action?.error?.message;
    });


    // get One patient
    builder.addCase(getOnePatientAction.pending, (state: PatientsState, action: any) => {
      state.loading = true;
      state.appErr = undefined;
      state.serverErr = undefined;
    });
    builder.addCase(getOnePatientAction.fulfilled, (state:PatientsState, action: any) => {
      state.loading = false;
      state.called = action?.payload;
      state.appErr = undefined;
      state.serverErr = undefined;
    });
    builder.addCase(getOnePatientAction.rejected, (state: PatientsState, action: any) => {
      state.loading = false;
      //state.appErr = action?.payload?.message;
      state.appErr = action?.payload?.JsonWebTokenError;
      state.serverErr = action?.error?.message;
    });

    // create user
    builder.addCase(createPatientAction.pending, (state: PatientsState, action: any) => {
      state.loading = true;
      state.appErr = undefined;
      state.serverErr = undefined;
    });
    builder.addCase(createPatientAction.fulfilled, (state:PatientsState, action: any) => {
      state.loading = false;
      state.created = action?.payload;
      state.appErr = undefined;
      state.serverErr = undefined;
    });
    builder.addCase(createPatientAction.rejected, (state: PatientsState, action: any) => {
      state.loading = false;
      //state.appErr = action?.payload?.message;
      state.appErr = action?.payload?.JsonWebTokenError;
      state.serverErr = action?.error?.message;
    });

    // update user
    builder.addCase(updatePatientAction.pending, (state: PatientsState, action: any) => {
      state.loading = true;
      state.appErr = undefined;
      state.serverErr = undefined;
    });
    builder.addCase(updatePatientAction.fulfilled, (state: PatientsState, action: any) => {
      state.loading = false;
      state.updated = action?.payload;
      state.appErr = undefined;
      state.serverErr = undefined;
    });
    builder.addCase(updatePatientAction.rejected, (state: PatientsState, action: any) => {
      state.loading = false;
      //state.appErr = action?.payload?.message;
      state.appErr = action?.payload?.JsonWebTokenError;
      state.serverErr = action?.error?.message;
    });
  },
} as any);

export default patientSlices.reducer;
